/* eslint-disable @typescript-eslint/no-var-requires */

import { UserStatus } from "./user/User";

export enum ToolbarNodeListType {
  DoctorDetailOptionList = "DoctorDetailOptionList",
  DoctorOptionList = "DoctorOptionList",
  PatientDetailOptionList = "PatientDetailOptionList",
  PatientOptionList = "PatientOptionList",
  ToolbarOptionList = "ToolbarOptionList",
  UserOptionList = "UserOptionList",
}

export enum ToolbarNodeType {
  DoctorDelete = "DoctorDelete",
  DoctorDetailEdit = "DoctorDetailEdit",
  DoctorDetailOptionList = "DoctorDetailOptionList",
  DoctorDetailResendInvitation = "DoctorDetailResendInvitation",
  DoctorList = "DoctorList",
  DoctorOptionList = "DoctorOptionList",
  PatientDetailOptionList = "PatientDetailOptionList",
  PatientDetailResendInvitation = "PatientDetailResendInvitation",
  PatientDetailEdit = "PatientDetailEdit",
  PatientList = "PatientList",
  PatientDelete = "PatientDelete",
  ParentDelete = "ParentDelete",
  PatientParentEdit = "PatientParentEdit",
  PatientOptionList = "PatientOptionList",
  SMSsettings = "SMSsettings",
  ToolbarOptionList = "ToolbarOptionList",
  UserEdit = "UserEdit",
  GlobalFormManagement = "GlobalFormManagement",
  IndividualFormManagement = "IndividualFormManagement",
  UserOptionList = "UserOptionList",
}

export type ToolbarTopSectionDetails = {
  includeSelectedUserName: boolean;
  includeSelectedParentName?: boolean;
  text: string;
};

type ToolbarOption = {
  backTo: ToolbarNodeType | null;
  topSection: ToolbarTopSectionDetails | null;
};

export const ToolbarNodeDetails: Record<ToolbarNodeType, ToolbarOption> = {
  [ToolbarNodeListType.ToolbarOptionList]: {
    topSection: null,
    backTo: null,
  },
  [ToolbarNodeListType.UserOptionList]: {
    topSection: null,
    backTo: ToolbarNodeType.ToolbarOptionList,
  },
  [ToolbarNodeType.UserEdit]: {
    topSection: {
      text: "Change your password",
      includeSelectedUserName: false,
    },
    backTo: ToolbarNodeType.UserOptionList,
  },
  [ToolbarNodeType.DoctorOptionList]: {
    topSection: null,
    backTo: ToolbarNodeType.ToolbarOptionList,
  },
  [ToolbarNodeType.DoctorList]: {
    topSection: {
      text: "Select doctor to perform actions",
      includeSelectedUserName: false,
    },
    backTo: ToolbarNodeType.DoctorOptionList,
  },
  [ToolbarNodeType.DoctorDetailOptionList]: {
    topSection: {
      text: "Doctor: ",
      includeSelectedUserName: true,
    },
    backTo: ToolbarNodeType.DoctorList,
  },
  [ToolbarNodeType.DoctorDetailResendInvitation]: {
    topSection: {
      text: "Resend: ",
      includeSelectedUserName: true,
    },
    backTo: ToolbarNodeType.DoctorDetailOptionList,
  },
  [ToolbarNodeType.DoctorDetailEdit]: {
    topSection: {
      text: "Edit: ",
      includeSelectedUserName: true,
    },
    backTo: ToolbarNodeType.DoctorDetailOptionList,
  },
  [ToolbarNodeType.DoctorDelete]: {
    topSection: {
      text: "Delete: ",
      includeSelectedUserName: true,
    },
    backTo: ToolbarNodeType.DoctorList,
  },
  [ToolbarNodeType.SMSsettings]: {
    topSection: {
      text: "Sms notifications settings",
      includeSelectedUserName: false,
    },
    backTo: ToolbarNodeType.ToolbarOptionList,
  },
  [ToolbarNodeType.GlobalFormManagement]: {
    topSection: {
      text: "Global form settings",
      includeSelectedUserName: false,
    },
    backTo: ToolbarNodeType.PatientOptionList,
  },
  [ToolbarNodeType.IndividualFormManagement]: {
    topSection: {
      text: "Individual form settings: ",
      includeSelectedUserName: true,
    },
    backTo: ToolbarNodeType.PatientDetailOptionList,
  },
  [ToolbarNodeType.PatientOptionList]: {
    topSection: null,
    backTo: ToolbarNodeType.ToolbarOptionList,
  },
  [ToolbarNodeType.PatientList]: {
    topSection: {
      text: "Select patient to perform actions",
      includeSelectedUserName: false,
    },
    backTo: ToolbarNodeType.PatientOptionList,
  },
  [ToolbarNodeType.PatientDetailOptionList]: {
    topSection: {
      text: "Patient: ",
      includeSelectedUserName: true,
    },
    backTo: ToolbarNodeType.PatientList,
  },
  [ToolbarNodeType.PatientDetailResendInvitation]: {
    topSection: {
      text: "Resend: ",
      includeSelectedUserName: true,
    },
    backTo: ToolbarNodeType.PatientDetailOptionList,
  },
  [ToolbarNodeType.PatientDelete]: {
    topSection: {
      text: "Delete: ",
      includeSelectedUserName: true,
    },
    backTo: ToolbarNodeType.PatientDetailOptionList,
  },
  [ToolbarNodeType.ParentDelete]: {
    topSection: {
      text: "Delete parent: ",
      includeSelectedUserName: false,
      includeSelectedParentName: true,
    },
    backTo: ToolbarNodeType.PatientParentEdit,
  },
  [ToolbarNodeType.PatientDetailEdit]: {
    topSection: {
      text: "Edit: ",
      includeSelectedUserName: true,
    },
    backTo: ToolbarNodeType.PatientDetailOptionList,
  },
  [ToolbarNodeType.PatientParentEdit]: {
    topSection: {
      text: "Edit parents: ",
      includeSelectedUserName: true,
    },
    backTo: ToolbarNodeType.PatientDetailOptionList,
  },
};

export type OptionElement = {
  text: string;
  key: string;
  img: string;
  redirectTo: ToolbarNodeType; // redirect to section within the toolbar widget
  href?: string; // redirect to other page with using window.location.href
  hideCondition?: UserStatus; // hide conditionally option if related user (ie. DoctorDetailOptionList) does not need that -> "resend invitation" for currently registered user
};

export const ToolbarOptionListChoice: Record<
  ToolbarNodeListType,
  OptionElement[]
> = {
  [ToolbarNodeListType.ToolbarOptionList]: [
    {
      text: "Manage your account",
      key: "manage-account",
      img: require("../../../img/settings2.png").default,
      redirectTo: ToolbarNodeType.UserOptionList,
    },
    {
      text: "Manage doctors",
      key: "manage-doctors",
      img: require("../../../img/doctor.png").default,
      redirectTo: ToolbarNodeType.DoctorOptionList,
    },
    {
      text: "Manage patients",
      key: "manage-patients",
      img: require("../../../img/patients2.png").default,
      redirectTo: ToolbarNodeType.PatientOptionList,
    },
    {
      text: "SMS Notification settings",
      key: "sms-settings",
      img: require("../../../img/smartphone.png").default,
      redirectTo: ToolbarNodeType.SMSsettings,
    },
    {
      text: "Schedule appointment",
      key: "schedule-appointment",
      img: require("../../../img/calendar.png").default,
      redirectTo: ToolbarNodeType.ToolbarOptionList,
    },
  ],
  [ToolbarNodeListType.UserOptionList]: [
    {
      text: "Change your password",
      key: "user-change-password",
      img: require("../../../img/settings2.png").default,
      redirectTo: ToolbarNodeType.UserEdit,
    },
  ],
  [ToolbarNodeListType.DoctorOptionList]: [
    {
      text: "Register new doctor",
      key: "doctor-new",
      img: require("../../../img/plus-white.png").default,
      redirectTo: null,
      href: "/register-user/?doctor=true",
    },
    {
      text: "Select doctor to perform action",
      key: "doctor-select",
      img: require("../../../img/multi-choice.png").default,
      redirectTo: ToolbarNodeType.DoctorList,
    },
  ],
  [ToolbarNodeListType.DoctorDetailOptionList]: [
    {
      text: "Resend invitation",
      key: "doctor-resend-invitation",
      img: require("../../../img/envelope-white.png").default,
      redirectTo: ToolbarNodeType.DoctorDetailResendInvitation,
      hideCondition: UserStatus.DoctorAccountConfirmed,
    },
    {
      text: "Edit",
      key: "doctor-edit",
      img: require("../../../img/edit.png").default,
      redirectTo: ToolbarNodeType.DoctorDetailEdit,
    },
    {
      text: "Delete",
      key: "doctor-delete",
      img: require("../../../img/trash.png").default,
      redirectTo: ToolbarNodeType.DoctorDelete,
    },
  ],
  [ToolbarNodeListType.PatientOptionList]: [
    {
      text: "Register new patient",
      key: "patient-register",
      img: require("../../../img/plus-white.png").default,
      redirectTo: null,
      href: "/register-user/",
    },
    {
      text: "Manage global patients forms",
      key: "patient-forms",
      img: require("../../../img/settings2.png").default,
      redirectTo: ToolbarNodeType.GlobalFormManagement,
    },
    {
      text: "Select patient to perform actions",
      key: "patient-select",
      img: require("../../../img/multi-choice.png").default,
      redirectTo: ToolbarNodeType.PatientList,
    },
  ],
  [ToolbarNodeListType.PatientDetailOptionList]: [
    {
      text: "Show in patient list",
      key: "patient-show-in-list",
      img: require("../../../img/arrow-right-white.png").default,
      redirectTo: null,
      href: "/patient-list/",
    },
    {
      text: "Resend invitation to parent",
      key: "patient-resend-invitation",
      img: require("../../../img/envelope-white.png").default,
      redirectTo: ToolbarNodeType.PatientDetailResendInvitation,
      // hideCondition: UserStatus.DoctorAccountConfirmed,  //todo
    },
    {
      text: "Manage individual patient's forms",
      key: "patient-manage-forms",
      img: require("../../../img/settings2.png").default,
      redirectTo: ToolbarNodeType.IndividualFormManagement,
    },
    {
      text: "Edit patient",
      key: "patient-edit",
      img: require("../../../img/edit.png").default,
      redirectTo: ToolbarNodeType.PatientDetailEdit,
    },
    {
      text: "Edit patient's parents",
      key: "patient-edit-parents",
      img: require("../../../img/settings2.png").default,
      redirectTo: ToolbarNodeType.PatientParentEdit,
    },
    {
      text: "Delete",
      key: "patient-delete",
      img: require("../../../img/trash.png").default,
      redirectTo: ToolbarNodeType.PatientDelete,
    },
  ],
};
