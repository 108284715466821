/* eslint-disable @typescript-eslint/no-var-requires */

import React from "react";
import { SearchInput } from "./SearchInput";
import {
  DropdownTreeMenuItem,
  SubMenuDropdownButton,
} from "./SubMenuDropdownButton";
import { FilterSection } from "./FilterSection";
import { PatientListFiltersType } from "../../api/storeActions";

const sortMenuItems: DropdownTreeMenuItem[] = [
  {
    label: "Patient",
    subItems: [
      { value: "fn_asc", label: "First name (ASC)" },
      { value: "fn_desc", label: "First name (DESC)" },
      {
        value: "ln_asc",
        label: "Last name (ASC)",
      },
      { value: "ln_desc", label: "Last name (DESC)" },
      { value: "af", label: "Activated (ASC)" },
      { value: "al", label: "Activated (DESC)" },
      { value: "aa", label: "Account archived" },
    ],
  },
  {
    label: "Doctor",
    subItems: [
      { value: "d_fn_asc", label: "First name (ASC)" },
      { value: "d_fn_desc", label: "First name (DESC)" },
      {
        value: "d_ln_asc",
        label: "Last name (ASC)",
      },
      { value: "d_ln_desc", label: "Last name (DESC)" },
    ],
  },
  {
    label: "DOB",
    subItems: [
      { label: "(ASC) ", value: "dob_asc" },
      { label: "(DESC) ", value: "dob_desc" },
    ],
  },
  {
    label: "Status",
    subItems: [
      { value: "fc", label: "Forms filled" },
      { value: "fs", label: "Forms sent" },
      { value: "fip", label: "Forms filling in progress" },
    ],
  },
];

type Props = {
  patientListFilters: PatientListFiltersType;
  searchBy: string;
  searchOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setPatientListFilters: (value: PatientListFiltersType) => void;
  setSortBy: (value: DropdownTreeMenuItem | null) => void;
  sortBy: DropdownTreeMenuItem;
};

export const PatientTableManagement: React.FC<Props> = ({
  patientListFilters,
  searchBy,
  searchOnChange,
  setPatientListFilters,
  setSortBy,
  sortBy,
}) => (
  <>
    <SearchInput searchBy={searchBy} onChange={searchOnChange} />
    <SubMenuDropdownButton
      selectedOption={sortBy}
      setSelectedOption={setSortBy}
      menuOptions={sortMenuItems}
      placeholder={"Add sorting"}
      label={"Sort by:"}
    />
    <FilterSection
      patientListFilters={patientListFilters}
      setPatientListFilters={setPatientListFilters}
    />
  </>
);
