/* eslint-disable @typescript-eslint/no-var-requires */

import React, { useCallback, useEffect, useState } from "react";
import { PatientTable } from "./PatientTable";
import styled from "styled-components";
import { Paginator } from "./Paginator";
import { useSelector } from "react-redux";
import { RootState, store } from "../../api/store";
import {
  PaginatorType,
  unreadMessageList,
  UserListType,
} from "../../api/action";
import { Patient } from "../toolbar/user/User";
import {
  getPatientListMainTableAction,
  PatientListFiltersInitialState,
  PatientListFiltersType,
} from "../../api/storeActions";
import { setPatientListMainTable } from "../../api/patientSlice";
import _ from "lodash";
import {
  DoctorListSortType,
  PatientListSortType,
} from "../toolbar/user/UserListActions";
import { DropdownTreeMenuItem } from "./SubMenuDropdownButton";
import { PatientTableManagement } from "./PatientTableManagement";

const StyledDiv = styled.div`
  box-sizing: border-box;
  padding: 0 40px;
  width: 100%;
`;

type OptionType = { value: number; label: string };

export const PatientList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState<OptionType>(null);
  const [pageSizeChange, setPageSizeChange] = useState(false);
  const [searchBy, setSearchBy] = useState<string>(null);
  const [sortBy, setSortBy] = useState<DropdownTreeMenuItem>(null);
  const [patientListFilters, setPatientListFilters] = useState<
    PatientListFiltersType
  >(PatientListFiltersInitialState);

  const token = useSelector((state: RootState) => state.auth.token);
  const patientListData: UserListType = useSelector(
    (state: RootState) => state.patient.patientListMainTable
  );
  const paginator: PaginatorType =
    patientListData?.paginator || ({} as PaginatorType);

  useEffect(() => {
    const pageSize = paginator?.page_size;
    if (pageSize) {
      setPageSize({ value: pageSize, label: `${pageSize}` });
    } else setPageSize(null);
  }, [paginator?.page_size]);

  const patientList: Patient[] =
    patientListData?.result?.map((data) => new Patient(data)) || [];

  useEffect(() => {
    getPatientListMainTableAction({}).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (patientList?.length) {
      intervalId = setInterval(() => {
        unreadMessageList({
          patientIds: patientList?.map((patient) => patient.id),
          token,
        }).then((unreadList) => {
          let updatePatientListData = false;
          const currentPatientListData = [...patientListData.result];
          for (const messageElement of unreadList) {
            for (let i = 0; i < currentPatientListData.length; i++) {
              if (messageElement.patient_id == currentPatientListData[i].id) {
                if (
                  messageElement.unread_message !=
                  currentPatientListData[i].unread_message
                ) {
                  updatePatientListData = true;
                  currentPatientListData[i] = {
                    ...currentPatientListData[i],
                    unread_message: messageElement.unread_message,
                  };
                }
              }
            }
          }
          if (updatePatientListData) {
            store.dispatch(
              setPatientListMainTable({
                ...patientListData,
                result: currentPatientListData,
              })
            );
          }
        });
      }, 10000);
    }
    return (): void => {
      clearInterval(intervalId);
    };
  }, [patientList?.map((patient) => patient.id).join(",")]);

  useEffect(() => {
    if (
      searchBy !== null ||
      sortBy !== null ||
      patientListFilters !== PatientListFiltersInitialState
    ) {
      debouncedFetchPatientList({
        force: true,
        reset: true,
        search: searchBy,
        sort: sortBy?.value,
        filters: convertFilters(patientListFilters),
      });
    }
  }, [searchBy, sortBy, patientListFilters]);

  const fetchPatientList = ({
    force = false,
    pageNumber = null,
    pageSize = null,
    filters = null,
    reset = false,
    search = null,
    sort = null,
  }: {
    force?: boolean;
    pageNumber?: number;
    pageSize?: number;
    filters?: object;
    reset?: boolean;
    search?: string;
    sort?: DoctorListSortType | PatientListSortType;
  }): Promise<void> => {
    setIsLoading(true);
    return getPatientListMainTableAction({
      force,
      pageNumber,
      pageSize,
      reset,
      search,
      sort,
      filters,
    }).then((): void => {
      setIsLoading(false);
    });
  };

  const debouncedFetchPatientList = useCallback(
    _.debounce(fetchPatientList, 1000),
    []
  );

  const searchOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;
    setSearchBy(value);
  };

  const onChangePageNumber = ({ selected }: { selected: number }): void => {
    fetchPatientList({
      force: true,
      pageNumber: selected + 1,
      pageSize: pageSize.value,
      search: searchBy,
      sort: sortBy?.value,
      filters: convertFilters(patientListFilters),
    });
  };

  const onChangePageSize = (selectedOption: OptionType): void => {
    setPageSizeChange(true);
    fetchPatientList({
      force: true,
      pageNumber: 1,
      pageSize: selectedOption.value,
      search: searchBy,
      sort: sortBy?.value,
      filters: convertFilters(patientListFilters),
    }).then((): void => {
      setPageSizeChange(false);
    });
  };

  const convertFilters = (obj: PatientListFiltersType): any => {
    const snakeCaseObj: { [key: string]: string | number } = {};

    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const newKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
        const keyOfObj = key as keyof PatientListFiltersType;

        if (
          obj[keyOfObj] &&
          "value" in obj[keyOfObj] &&
          "label" in obj[keyOfObj]
        ) {
          // @ts-ignore
          snakeCaseObj[newKey] = obj[keyOfObj].value;
        } else if (
          obj[keyOfObj] &&
          "date_from" in obj[keyOfObj] &&
          "date_to" in obj[keyOfObj]
        ) {
          // @ts-ignore
          const fromDate = obj[keyOfObj].date_from;
          // @ts-ignore
          const toDate = obj[keyOfObj].date_to;
          // @ts-ignore
          snakeCaseObj[newKey] = {
            date_from:
              fromDate instanceof Date
                ? `${fromDate.getFullYear()}-${(fromDate.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}-${fromDate
                    .getDate()
                    .toString()
                    .padStart(2, "0")}`
                : null,
            date_to:
              toDate instanceof Date
                ? `${toDate.getFullYear()}-${(toDate.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}-${toDate
                    .getDate()
                    .toString()
                    .padStart(2, "0")}`
                : null,
          };
        } else {
          // @ts-ignore
          snakeCaseObj[newKey] = obj[keyOfObj];
        }
      }
    }

    return snakeCaseObj;
  };

  return (
    <StyledDiv>
      <PatientTableManagement
        patientListFilters={patientListFilters}
        searchBy={searchBy}
        searchOnChange={searchOnChange}
        setPatientListFilters={setPatientListFilters}
        setSortBy={setSortBy}
        sortBy={sortBy}
      />
      <PatientTable patientList={patientList} isLoading={isLoading} />
      <Paginator
        onChangePageSize={onChangePageSize}
        onChangePageNumber={onChangePageNumber}
        pageSize={pageSize}
        pageSizeChange={pageSizeChange}
        paginator={paginator}
      />
    </StyledDiv>
  );
};
