import React, { Suspense, useEffect, lazy } from "react";
import { ToolbarTopSection } from "./ToolbarTopSection";
import { ToolbarBottomSection } from "./ToolbarBottomSection";
import { ToolbarNodeDetails, ToolbarNodeType } from "./ToolbarNode";
import { Spinner } from "../Spinner";
import { UserRole } from "./user/User";
import { RootState } from "../../api/store";
import { getLoggedUserAction } from "../../api/storeActions";
import { useSelector } from "react-redux";

const ToolbarOptionList = lazy(() => import("./ToolbarOptionList"));
const UserEdit = lazy(() => import("./user/user-section/UserEdit"));
const DoctorList = lazy(() => import("./user/doctor/DoctorList"));
const UserResendInvitation = lazy(() => import("./user/UserResendInvitation"));
const DoctorEdit = lazy(() => import("./user/doctor/DoctorEdit"));
const UserDelete = lazy(() => import("./user/UserDelete"));
const SMSsettings = lazy(() => import("./SMSsettings"));
const PatientList = lazy(() => import("./user/patient/PatientList"));
const FormManagement = lazy(() => import("./FormManagement"));
const PatientEdit = lazy(() => import("./user/patient/PatientEdit"));
const ParentEdit = lazy(() => import("./user/patient/ParentEdit"));

const renderComponent = (
  Component: React.ComponentType<any>,
  props = {}
): JSX.Element => (
  <Suspense fallback={<Spinner />}>
    <Component {...props} />
  </Suspense>
);

export const Toolbar: React.FC = () => {
  const loggedUser = useSelector((state: RootState) => state.auth.loggedUser);
  const selectedNode: ToolbarNodeType = useSelector(
    (state: RootState) => state.toolbar.selectedNode
  );

  useEffect(() => {
    getLoggedUserAction();
  }, []);

  const renderTopSection = (): React.ReactElement => {
    return ToolbarNodeDetails[selectedNode].topSection ? (
      <ToolbarTopSection />
    ) : null;
  };

  if (!loggedUser) {
    return (
      <div className="container">
        <Spinner />
      </div>
    );
  }

  const renderMiddleSectionReducer = (): React.ReactElement => {
    switch (selectedNode) {
      // consistent with enum ToolbarNodeListType
      case ToolbarNodeType.ToolbarOptionList:
      case ToolbarNodeType.UserOptionList:
      case ToolbarNodeType.DoctorOptionList:
      case ToolbarNodeType.DoctorDetailOptionList:
      case ToolbarNodeType.PatientOptionList:
      case ToolbarNodeType.PatientDetailOptionList:
        return renderComponent(ToolbarOptionList);
      case ToolbarNodeType.UserEdit:
        return renderComponent(UserEdit);
      case ToolbarNodeType.DoctorList:
        return renderComponent(DoctorList);
      case ToolbarNodeType.DoctorDetailResendInvitation:
        return renderComponent(UserResendInvitation, { role: UserRole.DOCTOR });
      case ToolbarNodeType.PatientDetailResendInvitation:
        return renderComponent(UserResendInvitation, {
          role: UserRole.PATIENT,
        });
      case ToolbarNodeType.DoctorDetailEdit:
        return renderComponent(DoctorEdit);
      case ToolbarNodeType.PatientDetailEdit:
        return renderComponent(PatientEdit, { role: UserRole.DOCTOR });
      case ToolbarNodeType.PatientDelete:
        return renderComponent(UserDelete, { role: UserRole.PATIENT });
      case ToolbarNodeType.ParentDelete:
        return renderComponent(UserDelete, { role: UserRole.PARENT });
      case ToolbarNodeType.DoctorDelete:
        return renderComponent(UserDelete, { role: UserRole.DOCTOR });
      case ToolbarNodeType.PatientList:
        return renderComponent(PatientList);
      case ToolbarNodeType.PatientParentEdit:
        return renderComponent(ParentEdit);
      case ToolbarNodeType.SMSsettings:
        return renderComponent(SMSsettings);
      case ToolbarNodeType.GlobalFormManagement:
      case ToolbarNodeType.IndividualFormManagement:
        return renderComponent(FormManagement);
      default:
        return (
          <div className="section section-middle">
            <Spinner />
          </div>
        );
    }
  };

  return (
    <div className="container">
      {renderTopSection()}
      <div className="section section-middle">
        {renderMiddleSectionReducer()}
      </div>
      <ToolbarBottomSection />
    </div>
  );
};
