import { CombinedState, configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import doctorReducer from "./doctorSlice";
import patientReducer from "./patientSlice";
import toolbarReducer from "./toolbarSlice";
import messageReducer from "./messageSlice";

import { Reducer } from "react";
import { ReducersMapObject } from "redux";
import { setupListeners } from "@reduxjs/toolkit/query";

export const storeReducer:
  | Reducer<CombinedState<any>, any>
  | ReducersMapObject = {
  auth: authReducer,
  doctor: doctorReducer,
  message: messageReducer,
  patient: patientReducer,
  toolbar: toolbarReducer,
};

export const store = configureStore({
  reducer: storeReducer,
});

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
