/* eslint-disable @typescript-eslint/no-var-requires */

import React, { lazy, useState } from "react";
import {
  setPatientChat,
  setSelectedNodeAction,
  setSelectedUserAction,
  updatePatientMainTableAction,
} from "../../api/storeActions";
import { Patient, UserStatus } from "../toolbar/user/User";
import styled, { keyframes } from "styled-components";
import { ToolbarNodeType } from "../toolbar/ToolbarNode";
import { PatientDetailNodeType } from "./PatientDetailNode";

const DetailedPatientSection = lazy(() => import("./DetailedPatientSection"));

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: 24% 18% 15% 8% 9% 6% 6% 6% 8%;
`;

interface TableRowProps {
  color?: string;
}

interface TableBodyCellProps {
  left: boolean;
  pointer?: boolean;
}

const TableBodyCell = styled.div<TableBodyCellProps>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-transform: none;
  ${({ left }): string =>
    left &&
    `
    justify-content: flex-start;
  `}
  ${({ pointer }): string =>
    pointer &&
    `
    cursor: pointer;
  `}
`;

const PatientNameWrapper = styled.div`
  padding-left: 10px;
`;

const newMessageAnimation = keyframes`
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

const MessageRedDot = styled.div`
  top: 20px;
  left: 55%;
  transform: translate(100%, 100%);
  position: absolute;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: red;
  z-index: 3;
  transition: 0.3s linear;
  transform: scale(1);
  animation: ${newMessageAnimation} 1s infinite alternate;
`;

const StyledCellImg = styled.img`
  margin: 0 !important;
  height: 15px !important;
  width: 20px !important;
`;
const AttachmentImg = styled.img`
  margin: 0 !important;
  height: 20px !important;
  width: 20px !important;
`;
const EnvelopeSendImg = styled.img`
  margin: 0 !important;
  height: 25px !important;
  width: 25px !important;
`;
const PDFImg = styled.img`
  margin: 0 !important;
  height: 25px !important;
  width: 18px !important;
`;

export const CustomTableRow = styled(TableGrid)<TableRowProps>`
  background-color: #fff;
  ${({ color }): string =>
    `
    color: ${color};
  `}
  font-size: 14px;
  padding: 0;
  margin-top: 0;
  min-height: 35px;
  border-bottom: 2px solid #ebfdfc;
  position: relative;
`;

type Props = {
  patient: Patient;
};

const getRowTextColor = (patient: Patient): string => {
  if (!patient.doctorId) {
    return "#E83B46";
  } else if (patient.status == UserStatus.PatientAccountArchived) {
    return "#84b1b5";
  } else if (patient.status == UserStatus.PatientSentForms) {
    return "#01d401";
  }
  return "#3c5153";
};

const getPatientStatus = (patient: Patient): string => {
  if (patient.status == UserStatus.PatientAccountArchived) {
    return "Archived";
  } else if (patient.status == UserStatus.PatientSentForms) {
    return "Sent";
  }
  return `${patient.totalCompletePercentage}%`;
};

export const PatientRow: React.FC<Props> = ({ patient }) => {
  const [
    patientDetailNode,
    setPatientDetailNode,
  ] = useState<PatientDetailNodeType | null>(null);

  const handleOnClick = (selectedType: PatientDetailNodeType): void => {
    if (selectedType == patientDetailNode) {
      setPatientDetailNode(null);
      return;
    }
    setPatientDetailNode(selectedType);
  };

  return (
    <>
      <CustomTableRow color={getRowTextColor(patient)}>
        <TableBodyCell
          key={"patient"}
          left={true}
          pointer={true}
          onClick={(): void =>
            handleOnClick(PatientDetailNodeType.PatientDetail)
          }
        >
          <PatientNameWrapper>{patient.fullName}</PatientNameWrapper>
        </TableBodyCell>
        <TableBodyCell
          key={"doctor"}
          left={true}
          pointer={true}
          onClick={(): void =>
            handleOnClick(PatientDetailNodeType.DoctorDetail)
          }
        >
          {patient.doctorId ? (
            patient.doctorFullName
          ) : (
            <EnvelopeSendImg
              src={require("../../../img/add-new-doctor-green.png").default}
            />
          )}
        </TableBodyCell>
        <TableBodyCell key={"dob"} left={false}>
          {patient.dob}
        </TableBodyCell>
        <TableBodyCell key={"status"} left={false}>
          {getPatientStatus(patient)}
        </TableBodyCell>
        <TableBodyCell
          key={"messages"}
          left={false}
          pointer={true}
          onClick={(): void => {
            setPatientChat(patient.data);
            if (patient.unreadMessage) {
              updatePatientMainTableAction({
                patientId: patient.id,
                updateDict: { unread_message: false },
              });
            }
          }}
        >
          <StyledCellImg src={require("../../../img/evelope.png").default} />
          {patient.unreadMessage && <MessageRedDot></MessageRedDot>}
        </TableBodyCell>
        <TableBodyCell
          key={"attachments"}
          left={false}
          pointer={true}
          onClick={(): void => {
            setPatientChat(patient.data, true);
          }}
        >
          <AttachmentImg
            src={require("../../../img/attachment-green.png").default}
          />
        </TableBodyCell>
        <TableBodyCell
          key={"resend"}
          left={false}
          pointer={true}
          onClick={(): void =>
            handleOnClick(PatientDetailNodeType.InvitationResendDetail)
          }
        >
          <EnvelopeSendImg
            src={
              require(`../../../img/send-envelop-${
                patient.anyEmailConfirmed ? "green" : "red"
              }.png`).default
            }
          />
        </TableBodyCell>
        <TableBodyCell
          key={"pdf"}
          left={false}
          pointer={true}
          onClick={(): void => handleOnClick(PatientDetailNodeType.PDFDetail)}
        >
          <PDFImg src={require("../../../img/pdf.png").default} />
        </TableBodyCell>
        <TableBodyCell
          key={"toolbar"}
          left={false}
          pointer={true}
          onClick={(): void => {
            const toolbar = document.getElementById("toolbar");
            if (toolbar) {
              setSelectedUserAction(patient.data);
              setSelectedNodeAction(ToolbarNodeType.PatientDetailOptionList);
              toolbar.classList.add("show");
            }
          }}
        >
          <AttachmentImg
            src={require("../../../img/action-green.png").default}
          />
        </TableBodyCell>
      </CustomTableRow>

      {patientDetailNode && (
        <React.Suspense fallback={<></>}>
          <DetailedPatientSection
            detailNode={patientDetailNode}
            patient={patient}
          />
        </React.Suspense>
      )}
    </>
  );
};
