import {
  PatientFormDetail,
  PatientFormListDetails,
  UserDetail,
} from "../../../api/action";

// keep consistent with cps/models.py::ROLE_TYPES
export enum UserRole {
  DOCTOR = "admin",
  PARENT = "patient",
  PATIENT = "raw_patient",
  PATIENT_MAIN_LIST = "patient_main_list",
}

export enum UserStatus {
  // keep consistent with cps.constants.UserStatus
  DoctorAccountConfirmed = "DoctorAccountConfirmed",
  DoctorAccountNotConfirmed = "DoctorAccountNotConfirmed",
  DoctorAccountNotActive = "DoctorAccountNotActive",
  PatientAccountArchived = "PatientAccountArchived",
  PatientAccountConfirmed = "PatientAccountConfirmed",
  PatientAccountNotConfirmed = "PatientAccountNotConfirmed",
  PatientCompletedAllForms = "PatientCompletedAllForms",
  PatientSentForms = "PatientSentForms",
}

export class User {
  data: UserDetail;

  constructor(data: UserDetail) {
    this.data = data;
  }

  get id(): number {
    return this.data.id;
  }

  get status(): UserStatus {
    return this.data.status as UserStatus;
  }

  get firstName(): string {
    return this.data.first_name;
  }

  get lastName(): string {
    return this.data.last_name;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get email(): string {
    return this.data.email;
  }

  get phone(): string {
    return this.data.phone || "";
  }

  get role(): UserRole {
    return this.data.role;
  }

  get isDoctor(): boolean {
    return this.role == UserRole.DOCTOR;
  }

  get isPermissionDenied(): boolean {
    return this.data.is_permission_denied;
  }

  get active(): boolean {
    return this.data.is_active;
  }

  get superuser(): boolean {
    return this.data.is_superuser;
  }

  get emailConfirmed(): boolean {
    return this.data.email_confirmed;
  }

  get lastEmailSentDate(): string {
    return new Date(this.data.last_email_sent).toLocaleString("en-US", {
      timeZone: "America/New_York",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  get unreadMessage(): boolean {
    return this.data.unread_message;
  }

  setMessageRead(): void {
    this.data.unread_message = false;
  }
}

export class Doctor extends User {
  get officeManager(): boolean {
    return this.data.is_staff;
  }

  get manageRestrictedAccess(): boolean {
    return this.data.allow_manage_restricted_access;
  }

  get restrictedPatientInfoAccess(): boolean {
    return this.data.has_access_to_restricted_records;
  }

  get doctorManagementAccess(): boolean {
    return this.data.allow_doctor_management;
  }
  get patientManagementAccess(): boolean {
    return this.data.allow_patient_management;
  }
}

export class Patient extends User {
  get anyEmailConfirmed(): boolean {
    return this.data.is_email_confirmed;
  }

  get totalCompletePercentage(): number {
    return this.data.total_complete_form_percent;
  }

  get dob(): string {
    return this.data.date_of_birth;
  }

  get formsSent(): boolean {
    return this.data.form_sent_back;
  }

  get archived(): boolean {
    return this.data.is_archived;
  }

  get feeWaived(): boolean {
    return this.data.is_fee_waived;
  }

  get onlyAccessRelatedDoctor(): boolean {
    return this.data.only_access_to_related_doctor;
  }

  get doctorId(): number {
    return this.data.doctor_id;
  }

  get doctorFullName(): string {
    return this.data.doctor_fullname;
  }

  get formList(): PatientFormDetail[] {
    return this.data.form_list_details.base;
  }
}
