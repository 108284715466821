/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";
import styled from "styled-components";
import { Spinner } from "../Spinner";
import { Patient } from "../toolbar/user/User";
import { PatientRow } from "./PatientRow";

const CustomTable = styled.div`
  width: 100%;
  text-transform: uppercase;
`;

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: 24% 18% 15% 8% 9% 6% 6% 6% 8%;
`;

const TableHeader = styled(TableGrid)`
  width: 100%;
  text-transform: uppercase;
  background-color: #84b1b5 !important;
  color: #fff;
  font-size: 12px;
  padding: 15px 0;
  font-weight: 400;
`;

const TableHeaderCell = styled.div`
  display: flex;
  justify-content: center;
`;

interface TableRowProps {
  color?: string;
}
const TableRow = styled(TableGrid)<TableRowProps>`
  background-color: #fff;
  ${({ color }): string =>
    `
    color: ${color};
  `}
  font-size: 14px;
  padding: 0;
  margin-top: 0;
  height: 35px;
  border-bottom: 2px solid #ebfdfc;
  position: relative;
`;

const TableRowFullWidth = styled(TableRow)`
  grid-template-columns: 1fr;
  min-height: 200px;
`;

const NoRecordWrapper = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  height: 100px;
  background-color: white;
`;

const columns: string[] = [
  "patient",
  "doctor",
  "dob",
  "status",
  "messages",
  "atts.",
  "resend",
  "pdf",
  "toolbar",
];

type Props = {
  isLoading: boolean;
  patientList: Patient[];
};

export const PatientTable: React.FC<Props> = ({ isLoading, patientList }) => {
  const renderHeader = (): JSX.Element => (
    <TableHeader>
      {columns?.map((column) => (
        <TableHeaderCell key={column}>{column}</TableHeaderCell>
      ))}
    </TableHeader>
  );

  const renderBody = (): JSX.Element => {
    if (isLoading) {
      return (
        <TableRowFullWidth>
          <Spinner />
        </TableRowFullWidth>
      );
    }

    return (
      <div>
        {patientList.length ? (
          patientList.map((patient) => (
            <PatientRow key={patient.id} patient={patient} />
          ))
        ) : (
          <NoRecordWrapper>There is no record</NoRecordWrapper>
        )}
      </div>
    );
  };

  return (
    <CustomTable>
      {renderHeader()}
      {renderBody()}
    </CustomTable>
  );
};
