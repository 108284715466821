import "./utils/bootstrap";

export const NOOP = () => {};

// use this solution when you want to interract with django by doing something in "onDataChanged", ie. refresh, submit etc
window.glue = {
  // testDiv: {
  //   forDiv: (div, options) => {
  //     const jsx = <Test onDataChanged={options.onDataChanged || NOOP} />;
  //     ReactDOM.render(jsx, div);
  //   },
  // },
};

document.addEventListener("DOMContentLoaded", () => {
  if (document.body) {
    document.body.parentNode.style.display = "inline";
  }
});
