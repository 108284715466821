import axios from "axios";
import { TextAppearanceType } from "../components/toolbar/InlineInformationText";
import { UserRole, UserStatus } from "../components/toolbar/user/User";
import {
  DoctorListSortType,
  PatientListSortType,
} from "../components/toolbar/user/UserListActions";
import { deepKeysToSnakeCase } from "../utils/globalFunctions";

const api = axios.create();

export enum UserManagementAction {
  CHANGE_PASSWORD = "change_password",
  DOCTOR_EDIT_DETAILS_SELECTED_DOCTOR = "doctor_edit_details_selected_doctor",
  DOCTOR_DELETE = "doctor_delete",
  DOCTOR_EDIT_UPDATE_DOCTOR = "doctor_edit_update_doctor",
  LOGGED_USER_DETAIL = "logged_user_detail",
  RESEND_INVITATION = "resend_invitation",
  RESEND_INVITATION_DETAILS = "resend_invitation_details",
  PATIENT_EDIT_DETAILS_SELECTED_PATIENT = "patient_edit_details_selected_patient",
  PATIENT_EDIT_UPDATE_PATIENT = "patient_edit_update_patient",
  PATIENT_DELETE = "patient_delete",
  PARENT_DELETE = "parent_delete",
  PATIENT_EDIT_PARENT = "patient_edit_parent",
  PATIENT_EDIT_PARENT_UPDATE = "patient_edit_parent_update",
  TEST = "test",
}

export type UserChangePasswordRequest = {
  action: UserManagementAction.CHANGE_PASSWORD;
  old_password: string;
  password: string;
};

export type LoggedUserDetailRequest = {
  action: UserManagementAction.LOGGED_USER_DETAIL;
};

export type UserTest = {
  action: UserManagementAction.TEST;
};

export type UserResendInvitationRequest = {
  action: UserManagementAction.RESEND_INVITATION;
  id: number;
};

export type UserResendInvitationDetailsRequest = {
  action: UserManagementAction.RESEND_INVITATION_DETAILS;
  id: number;
  role: UserRole;
};

export type DoctorEditDetailsRequest = {
  action: UserManagementAction.DOCTOR_EDIT_DETAILS_SELECTED_DOCTOR;
  id: number;
};

export type PatientParentEditRequest = {
  action: UserManagementAction.PATIENT_EDIT_PARENT;
  id: number;
};

export type PatientEditDetailsRequest = {
  action: UserManagementAction.PATIENT_EDIT_DETAILS_SELECTED_PATIENT;
  id: number;
};

export type DoctorDeleteRequest = {
  action: UserManagementAction.DOCTOR_DELETE;
  id: number;
};

export type DoctorEditUpdatesRequest = {
  action: UserManagementAction.DOCTOR_EDIT_UPDATE_DOCTOR;
  allow_doctor_management: boolean;
  allow_manage_restricted_access: boolean;
  allow_patient_management: boolean;
  email: string;
  first_name: string;
  has_access_to_restricted_records: boolean;
  id: number;
  is_active: boolean;
  is_staff: boolean;
  last_name: string;
  phone: string;
};

export type PatientEditUpdatesRequest = {
  action: UserManagementAction.PATIENT_EDIT_UPDATE_PATIENT;
  date_of_birth: string;
  doctor: number;
  first_name: string;
  form_sent_back: boolean;
  id: number;
  is_archived: boolean;
  is_fee_waived: boolean;
  only_access_to_related_doctor: boolean;
  last_name: string;
};

export type PatientEditParentUpdateRequest = {
  action: UserManagementAction.PATIENT_EDIT_PARENT_UPDATE;
  email: string;
  first_name: string;
  id?: number;
  is_active: boolean;
  last_name: string;
  patient_id: number;
  phone: string;
};

export type PatientDeleteRequest = {
  action: UserManagementAction.PATIENT_DELETE;
  id: number;
};

export type ParentDeleteRequest = {
  action: UserManagementAction.PARENT_DELETE;
  parent_id: number;
  patient_id: number;
};

export type UserManagementRequest =
  | DoctorEditDetailsRequest
  | DoctorDeleteRequest
  | DoctorEditUpdatesRequest
  | LoggedUserDetailRequest
  | UserChangePasswordRequest
  | UserResendInvitationDetailsRequest
  | UserResendInvitationRequest
  | PatientDeleteRequest
  | PatientEditDetailsRequest
  | PatientEditUpdatesRequest
  | PatientParentEditRequest
  | PatientEditParentUpdateRequest
  | ParentDeleteRequest
  | UserTest;

export interface InfoType {
  appearance: TextAppearanceType;
  message: string;
}

export interface DoctorPatient {
  date_of_birth: string;
  first_name: string;
  id: string;
  last_name: string;
}

export interface PatientFormDetail {
  display_name?: string;
  form_name: string;
  is_completed: boolean;
  is_doctor_only: boolean;
  is_excluded: boolean;
}

export interface PatientFormListDetails {
  base: PatientFormDetail[];
  completed_percent: number;
}

export interface UserDetail {
  email?: string;
  email_confirmed?: boolean;
  first_name: string;
  id: number;
  is_active?: boolean;
  last_email_sent?: string;
  last_name: string;
  phone?: string;
  role: UserRole;
  status: UserStatus | string;
  unread_message?: boolean;

  // doctor
  allow_doctor_management?: boolean;
  allow_manage_restricted_access?: boolean;
  allow_patient_management?: boolean;
  has_access_to_restricted_records?: boolean;
  is_staff?: boolean;
  is_superuser?: boolean;

  // patient
  is_email_confirmed?: boolean;
  date_of_birth?: string;
  doctor_fullname?: string;
  doctor_id?: number;
  form_list_details?: PatientFormListDetails;
  form_sent_back?: boolean;
  is_archived?: boolean;
  is_fee_waived?: boolean;
  is_permission_denied?: boolean;
  only_access_to_related_doctor?: boolean;
  total_complete_form_percent?: number;
}

export interface PaginatorType {
  total_pages: number;
  page_number: number;
  page_size: number;
}

type UserManagementResponse = InfoType | UserDetail | UserDetail[];

export const FormType = "global" || "individual";

export interface FormListType {
  name: string;
  display_name: string;
  value: boolean;
}

export async function userManagement({
  request,
  token,
}: {
  request: UserManagementRequest;
  token: string;
}): Promise<UserManagementResponse> {
  const { action, ...request_data } = request;
  const { data } = await api.post(
    `/api/_internal/user_management/${action}`,
    request_data,
    {
      headers: { "X-CSRFToken": token },
    }
  );
  return data;
}

export interface UserListType {
  result: UserDetail[];
  paginator: PaginatorType;
}

export async function userList({
  filters = null,
  noPagination = false,
  pageNumber,
  pageSize,
  role,
  search = null,
  sort = null,
  token,
}: {
  filters?: object;
  noPagination?: boolean;
  pageNumber?: number;
  pageSize?: number;
  role: UserRole;
  search?: string;
  sort?: DoctorListSortType | PatientListSortType;
  token: string;
}): Promise<UserListType> {
  const { data } = await api.post(
    `/api/_internal/user/user_list`,
    {
      filters: deepKeysToSnakeCase(filters),
      no_pagination: noPagination,
      page_number: pageNumber,
      page_size: pageSize,
      role,
      search,
      sort,
    },
    {
      headers: { "X-CSRFToken": token },
    }
  );
  return data;
}

export async function doctorPatientList({
  id,
  token,
}: {
  id: number;
  token: string;
}): Promise<DoctorPatient[]> {
  const { data } = await api.post(
    `/api/_internal/user/doctor_patient_list`,
    { id },
    {
      headers: { "X-CSRFToken": token },
    }
  );
  return data;
}

export async function SMSNotificationSettingsLocal({
  token,
}: {
  token: string;
}): Promise<boolean> {
  const { data } = await api.get(
    `/api/_internal/sms_management/local/sms_notification`,
    {
      headers: { "X-CSRFToken": token },
    }
  );
  return data.is_sms_notification_enabled;
}

export async function SMSNotificationSettingsLocalUpdate({
  smsNotification,
  token,
}: {
  smsNotification: boolean;
  token: string;
}): Promise<InfoType> {
  const { data } = await api.post(
    `/api/_internal/sms_management/local/sms_notification_update`,
    { sms_enabled: smsNotification },
    {
      headers: { "X-CSRFToken": token },
    }
  );
  return data;
}

export interface FormListDataType {
  formType: typeof FormType;
  token: string;
  userId?: number;
}

export async function getFormList({
  formType,
  token,
  userId,
}: FormListDataType): Promise<{
  forms: FormListType[];
  individual_form_settings_active?: boolean;
  is_disabled?: boolean;
}> {
  const { data } = await api.post(
    `/api/_internal/form_management/form_list`,
    { form_type: formType, user_id: userId },
    {
      headers: { "X-CSRFToken": token },
    }
  );
  return data;
}

export async function updateFormList({
  formList,
  formType,
  individualFormSettingsActive,
  token,
  userId,
}: {
  formList: string[];
  formType: typeof FormType;
  token: string;
  userId?: number;
  individualFormSettingsActive?: boolean;
}): Promise<InfoType> {
  const { data } = await api.post(
    `/api/_internal/form_management/form_list_update`,
    {
      form_type: formType,
      form_list: formList,
      user_id: userId,
      individual_form_settings_active: individualFormSettingsActive,
    },
    {
      headers: { "X-CSRFToken": token },
    }
  );
  return data;
}

export interface PatientFormListDataType {
  token: string;
  userId?: number;
}

export type FormDetails = {
  [key: string]: {
    display_name: string;
    url: string;
  };
};

export async function getFormDetails({
  token,
}: PatientFormListDataType): Promise<FormDetails> {
  const { data } = await api.get(
    `/api/_internal/form_management/form_details`,
    {
      headers: { "X-CSRFToken": token },
    }
  );
  return data;
}

export async function PDFRequestDownload({
  token,
  userId,
  withEmptyForms,
}: {
  token: string;
  userId: number;
  withEmptyForms: boolean;
}): Promise<unknown> {
  const { data } = await api.get(`/pdf/create`, {
    headers: { "X-CSRFToken": token },
    params: { user_id: userId, with_empty: withEmptyForms },
  });
  return data;
}

export interface UnreadMessageListType {
  patient_id: number;
  unread_message: boolean;
}

export async function unreadMessageList({
  patientIds,
  token,
}: {
  patientIds: number[];
  token: string;
}): Promise<UnreadMessageListType[]> {
  const { data } = await api.post(
    `/api/_internal/conversation/unread_message_list`,
    { patient_ids: patientIds },
    {
      headers: { "X-CSRFToken": token },
    }
  );
  return data;
}

export interface MessageDetailType {
  attachment: any;
  attachment_name: string;
  chat: number;
  from_user: UserRole;
  id: number;
  message: string;
  old_related_doctor_fullname: string;
  read_at: string;
  sent_at: string;
  user_initials: string;
}

export async function messageList({
  onlyAttachments,
  patientId,
  token,
}: {
  onlyAttachments: boolean;
  patientId: number;
  token: string;
}): Promise<MessageDetailType[]> {
  const { data } = await api.post(
    `/api/_internal/conversation/message_list`,
    { patient_id: patientId, only_attachments: onlyAttachments },
    {
      headers: { "X-CSRFToken": token },
    }
  );
  return data;
}

export async function messageSend({
  patientId,
  token,
  message,
  attachment,
}: {
  patientId: number;
  token: string;
  message: string;
  attachment?: File;
}): Promise<InfoType> {
  const formData = new FormData();
  formData.append("patient_id", patientId.toString());
  formData.append("message", message);

  if (attachment) {
    formData.append("attachment", attachment, attachment.name);
  }
  console.log("formDAta", formData);
  const { data } = await api.post(
    `/api/_internal/conversation/message_send`,
    formData,
    {
      headers: { "X-CSRFToken": token, "Content-Type": undefined },
    }
  );
  return data;
}
