import React from "react";
import styled from "styled-components";
import { UserInformation } from "./user/UserInformation";

const StyledDiv = styled.div`
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PermissionDenied: React.FC = () => {
  return (
    <StyledDiv>
      <UserInformation
        htmlContent={""}
        icon={"error"}
        header={"Permission denied"}
      />
    </StyledDiv>
  );
};
