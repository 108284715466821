/* eslint-disable @typescript-eslint/no-var-requires */

import React from "react";

import styled from "styled-components";
import { SemanticCOLORS } from "semantic-ui-react";

export enum TextAppearanceType {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
}

type TextAppearanceDetails = {
  color: SemanticCOLORS | null;
  icon: string;
};

export const TextAppearanceDetails: Record<
  TextAppearanceType,
  TextAppearanceDetails
> = {
  [TextAppearanceType.ERROR]: {
    color: "red",
    icon: require("../../../img/error.png").default,
  },
  [TextAppearanceType.SUCCESS]: {
    color: null,
    icon: require("../../../img/success.png").default,
  },
  [TextAppearanceType.WARNING]: {
    color: "yellow",
    icon: require("../../../img/warning.png").default,
  },
};

interface StyledProps {
  textColor: string;
  small: boolean;
}
const StyledDiv = styled.div<StyledProps>`
  display: flex;
  width: 100%;
  color: ${({ textColor }): string => textColor || "lightgreen"} !important;
  ${({ small }): string =>
    small
      ? `
     justify-content: flex-start;
   font-size: 10px;
     img {
    height: 12px !important;
    width: 12px !important;
    margin-right: 5px !important;
  }
  `
      : `
  justify-content: center;
   font-size: 14px;
     img {
    height: 18px !important;
    width: 18px !important;
    margin-right: 5px !important;
  }
  `}
`;

const StyledP = styled.p<StyledProps>`
  margin: 0;
  color: ${({ textColor }): string => textColor || "lightgreen"} !important;
  ${({ small }): string =>
    small
      ? `
     font-size: 10px;
  font-weight: bold;
  text-align: left;
  color: #84b1b5 !important;
  `
      : `
  font-size: 14px;
  font-weight: bold;
  `}
`;

type Props = {
  appearance: TextAppearanceType;
  message: string;
  small?: boolean;
};

export const InlineInformationText: React.FC<Props> = ({
  appearance,
  message,
  small = false,
}) => {
  const { color, icon } = TextAppearanceDetails[appearance];
  return (
    <StyledDiv textColor={color} small={small}>
      <img src={icon} />
      <StyledP
        textColor={color}
        small={small}
        dangerouslySetInnerHTML={{ __html: message }}
      ></StyledP>
    </StyledDiv>
  );
};
