/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback } from "react";
import styled from "styled-components";
import { FormDetails, PatientFormDetail } from "../../api/action";
import { useSelector } from "react-redux";
import { RootState } from "../../api/store";
import { Patient } from "../toolbar/user/User";
import { Spinner } from "../Spinner";

export const PatientRowHeader = styled.div`
  height: 25px;
  line-height: 25px;
  width: 100%;
  color: white;
  margin-bottom: 5px;
  background-color: rgb(132, 177, 181);
  text-align: center;
  font-size: 12px;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin-top: 5px;
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(132, 177, 181);
  padding-bottom: 5px;
`;

const FormStyled = styled.div`
  padding-left: 5px;
  display: flex;
`;

const LegendIconWrapper = styled.div`
  display: flex;
`;

const FormName = styled.p`
  margin: 0;
  font-size: 12px;
  cursor: pointer;
  text-align: left;
`;

const LegendWrapper = styled.div`
  .legend {
    font-size: 14px;
  }
`;

const LegendItem = styled.div`
  display: flex;
`;

const StyledImg = styled.img`
  margin: 0 5px 0 0 !important;
  height: 12px !important;
  width: 12px !important;
`;

type Props = {
  formList: PatientFormDetail[];
  patient: Patient;
};

const openInNewTab = (url: string, patientId: number): void => {
  const newWindow = window.open(
    `${url}?user_id=${patientId}`,
    "_blank",
    "noopener,noreferrer"
  );
  if (newWindow) newWindow.opener = null;
};

const GetForm = React.memo<{
  formDetails: FormDetails;
  form: PatientFormDetail;
  patientId: number;
}>(({ formDetails, form, patientId }, areEquat) => {
  const { display_name, url } = formDetails[form.form_name];

  const handleOnClick = useCallback(() => {
    openInNewTab(url, patientId);
  }, [url, patientId]);

  return (
    <FormStyled>
      <LegendIconWrapper>
        <StyledImg
          src={
            require(`../../../img/${
              form.is_completed ? "checked-green" : "error"
            }.png`).default
          }
        />
        {form.is_doctor_only && (
          <StyledImg
            src={require("../../../img/doctor-like-button.png").default}
          />
        )}
        {form.is_excluded && (
          <StyledImg src={require("../../../img/info-grey.png").default} />
        )}
        {form.form_name == "contractforservices" && (
          <StyledImg src={require("../../../img/info-blue.png").default} />
        )}
      </LegendIconWrapper>
      <FormName onClick={handleOnClick}>{display_name}</FormName>
    </FormStyled>
  );
});

GetForm.displayName = "FormListGetForm";

export const FormList: React.FC<Props> = ({ formList, patient }) => {
  const formDetails: FormDetails = useSelector(
    (state: RootState) => state.patient.formDetails
  );

  if (!Object.keys(formDetails).length) return <Spinner />;

  const sortedForms = React.useMemo(
    () =>
      formList
        .map(
          (form): PatientFormDetail => ({
            ...form,
            display_name: formDetails[form.form_name].display_name,
          })
        )
        .sort((a, b) => a.display_name.localeCompare(b.display_name)),
    [formList, formDetails]
  );

  return (
    <BodyWrapper>
      <FormWrapper>
        {sortedForms.map((form) => (
          <GetForm
            key={form.form_name}
            form={form}
            patientId={patient.id}
            formDetails={formDetails}
          />
        ))}
      </FormWrapper>
      <LegendWrapper>
        <p className={"legend"}>Legend:</p>
        <LegendItem>
          <div>
            <StyledImg
              src={require("../../../img/doctor-like-button.png").default}
            />
          </div>
          <div>
            <small>Form to be filled out only by a doctor</small>
          </div>
        </LegendItem>
        <LegendItem>
          <div>
            <StyledImg
              src={require("../../../img/checked-green.png").default}
            />
          </div>
          <div>
            <small>Form filled</small>
          </div>
        </LegendItem>
        <LegendItem>
          <div>
            <StyledImg src={require("../../../img/error.png").default} />
          </div>
          <div>
            <small>Form not filled</small>
          </div>
        </LegendItem>
        <LegendItem>
          <div>
            <StyledImg src={require("../../../img/info-grey.png").default} />
          </div>
          <div>
            <small>Form not required</small>
          </div>
        </LegendItem>
        <LegendItem>
          <div>
            <StyledImg src={require("../../../img/info-blue.png").default} />
          </div>
          <div>
            <small>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Setting a requirement to fill out the form in the 'Patient Edit'
              section
            </small>
          </div>
        </LegendItem>
      </LegendWrapper>
    </BodyWrapper>
  );
};
