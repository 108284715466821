import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToolbarNodeType } from "../components/toolbar/ToolbarNode";
import { UserDetail } from "./action";

export type ToolbarState = {
  selectedNode: ToolbarNodeType;
  selectedParent: UserDetail;
  selectedUser: UserDetail;
};

const toolbarSlice = createSlice({
  name: "toolbarSlice",
  initialState: {
    selectedNode: ToolbarNodeType.ToolbarOptionList,
    selectedParent: null,
    selectedUser: null,
  } as ToolbarState,
  reducers: {
    setSelectedNode: (
      state,
      { payload: node }: PayloadAction<ToolbarNodeType>
    ) => {
      state.selectedNode = node;
    },
    setSelectedParent: (
      state,
      { payload: parentData }: PayloadAction<UserDetail>
    ) => {
      state.selectedParent = parentData;
    },
    setSelectedUser: (
      state,
      { payload: userData }: PayloadAction<UserDetail>
    ) => {
      state.selectedUser = userData;
    },
  },
});

export const {
  setSelectedNode,
  setSelectedParent,
  setSelectedUser,
} = toolbarSlice.actions;

export default toolbarSlice.reducer;
