import React from "react";
import styled from "styled-components";
import { MessageDetailType } from "../../api/action";
import { MessageDetailsAttachment } from "./MessageDetailsAttachment";

const RecipientMessageBox = styled.div`
  padding-bottom: 15px;
  display: flex;
  padding-left: 15px;
`;

const SenderMessageBox = styled.div`
  padding-bottom: 15px;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 10px;

  &:nth-child(1) {
    margin-bottom: 15px;
  }
`;

const RecipientMessageBubble = styled.div`
  height: 50px;
  flex-basis: 50px;
  border-radius: 50%;
  background-image: linear-gradient(
    90deg,
    rgb(54, 200, 231) 0%,
    rgb(27, 213, 219) 39%,
    rgb(0, 225, 206) 99%
  );
  color: white;
  text-transform: uppercase;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
`;

const SenderMessageBubble = styled.div`
  height: 50px;
  flex-basis: 50px;
  border-radius: 50%;
  color: white;
  text-transform: uppercase;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  background-color: #84b1b5;
`;

const RecipientMessageContentWrapper = styled.div`
  position: relative;
  color: white;
  margin: 25px 80px 0 25px;
  flex-basis: 230px;
  background-color: #37c6e5;
  border-radius: 20px;
  text-align: left;
  padding: 15px;

  &::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: -10px;
    width: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #37c6e5;
  }
`;

const SenderMessageContentWrapper = styled.div`
  position: relative;
  color: white;
  flex-basis: 230px;
  background-color: #37c6e5;
  border-radius: 20px;
  text-align: left;
  padding: 15px;
  margin: 25px 25px 0 25px;
  background-color: #10decc;

  &::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 88%;
    width: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #10decc;
  }
`;

const MessageContent = styled.div`
  word-break: break-all;
`;

const MessageSeen = styled.div`
  position: absolute;
  color: #84b1b5;
  bottom: -15px;
  right: 10px;
  font-size: 10px;
`;

const MessageSentBy = styled.div`
  position: absolute;
  color: #84b1b5;
  bottom: -25px;
  right: 10px;
  font-size: 10px;
`;

export enum MessageType {
  SENDER = "sender",
  RECIPIENT = "recipient",
}

type Props = {
  message: MessageDetailType;
  messageType: MessageType;
};

export const MessageDetails: React.FC<Props> = ({ message, messageType }) => {
  const {
    attachment,
    attachment_name: attachmentName,
    message: textMessage,
    old_related_doctor_fullname: sentBy,
    read_at: seenAt,
    sent_at: sentAt,
    user_initials: bubbleText,
  } = message;

  const messageContent = (
    <>
      <MessageContent>
        {textMessage}
        {attachment && (
          <MessageDetailsAttachment
            attachment={attachment}
            attachmentName={attachmentName}
          />
        )}
      </MessageContent>
      <MessageSeen>
        {seenAt ? `Seen at ${seenAt}` : `Sent ${sentAt}`}
      </MessageSeen>
      {sentBy && <MessageSentBy>Sent by {sentBy}</MessageSentBy>}
    </>
  );

  if (messageType == "sender") {
    return (
      <SenderMessageBox>
        <SenderMessageBubble>{bubbleText}</SenderMessageBubble>
        <SenderMessageContentWrapper>
          {messageContent}
        </SenderMessageContentWrapper>
      </SenderMessageBox>
    );
  }
  if (messageType == "recipient") {
    return (
      <RecipientMessageBox>
        <RecipientMessageBubble>{bubbleText}</RecipientMessageBubble>
        <RecipientMessageContentWrapper>
          {messageContent}
        </RecipientMessageContentWrapper>
      </RecipientMessageBox>
    );
  }
};
