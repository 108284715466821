/* eslint-disable @typescript-eslint/no-var-requires */

import React, { ChangeEvent, useRef, useState } from "react";
import { truncateName } from "../../utils/globalFunctions";
import styled from "styled-components";
import {
  InlineInformationText,
  TextAppearanceType,
} from "../toolbar/InlineInformationText";
import { useSelector } from "react-redux";
import { RootState } from "../../api/store";
import { InfoType, messageSend } from "../../api/action";
import { getChatMessages } from "../../api/storeActions";
import { Spinner } from "../Spinner";

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  position: relative;
`;

const AttachmentSection = styled.div`
  margin: 0 15px;
`;

const StyledImg = styled.img`
  cursor: pointer;
`;

const StyledInput = styled.input`
  border-radius: 20px !important;
  width: 90% !important;
`;

const AttachmentIconWrapper = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0 20px;
  border-radius: 50%;
  background-color: #10decc;
`;

const FooterAttachment = styled(Footer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

const FileNameWrapper = styled.div`
  display: block;
  flex-grow: 1;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  color: #84b1b5;
`;
const RemoveFileWrapper = styled.div`
  flex-grow: 0;
  margin: 0 20px;
  cursor: pointer;
`;

const AttachmentIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SendButton = styled.div`
  height: 30px;
  width: 30px;
  background-color: #10decc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  // margin-left: 10px;
  cursor: pointer;
  margin: 0 15px;
`;

const InputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`;

const SpinnerWrapper = styled.div`
  height: 40px;
  width: 40px;
  div {
    transform: scale(0.6);
  }
`;

export const ConversationBoxFooter: React.FC = () => {
  const [file, setFile] = useState<File>(null);
  const [inputValue, setInputValue] = useState("");
  const [info, setInfo] = useState<InfoType | null>(null);
  const [isSending, setIsSending] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isLoading: boolean = useSelector(
    (state: RootState) => state.message.isLoading
  );
  const selectedUserData = useSelector(
    (state: RootState) => state.message.selectedUser
  );
  const token = useSelector((state: RootState) => state.auth.token);

  const handleUploadClick = (): void => {
    inputRef.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) {
      return;
    }
    setFile(e.target.files[0]);
  };

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter") {
      send();
    }
  };

  const send = (): void => {
    if (!isLoading && validate() && !isSending) {
      const patientId = selectedUserData.id;
      setIsSending(true);
      messageSend({
        patientId,
        token,
        message: inputValue,
        attachment: file,
      })
        .then((data) => {
          getChatMessages({ onlyAttachments: false, patientId }).then(() => {
            setIsSending(false);
            setInputValue("");
            setFile(null);
            setInlineInfo(data);
          });
        })
        .catch((error) => {
          setIsSending(false);
          setInlineInfo({
            message: error.response.data.detail,
            appearance: TextAppearanceType.ERROR,
          });
        });
    }
  };

  // const displayErrorMessage = (message: string): void => {
  //   setErrorMessage(message);
  //
  //   setTimeout(() => {
  //     setErrorMessage("");
  //   }, 5000);
  // };

  const setInlineInfo = (data: InfoType): void => {
    setInfo(data);
    setTimeout(() => {
      setInfo(null);
    }, 5000);
  };

  const validate = (): boolean => {
    if (inputValue.length < 3 && !file) {
      setInlineInfo({
        message: "Message must contain at least 3 characters",
        appearance: TextAppearanceType.ERROR,
      });
      return false;
    }
    return true;
  };

  return (
    <div>
      <Footer>
        <AttachmentSection>
          <StyledImg
            src={require(`../../../img/plus-green-bg.png`).default}
            onClick={handleUploadClick}
          />
          <input
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </AttachmentSection>
        <InputWrapper>
          <StyledInput
            placeholder="Type..."
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              const { value } = e.currentTarget;
              setInputValue(value);
            }}
            onKeyDown={handleKeyDown}
            value={inputValue}
            disabled={isSending}
          />
          {info && <InlineInformationText {...info} small={true} />}
        </InputWrapper>
        {isSending ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <SendButton
            onClick={(): void => {
              send();
            }}
          >
            <img src={require(`../../../img/arrow-right.png`).default} />
          </SendButton>
        )}
      </Footer>
      {file && (
        <FooterAttachment>
          <AttachmentIconWrapper>
            <AttachmentIcon
              src={require(`../../../img/attachment.png`).default}
            />
          </AttachmentIconWrapper>
          <FileNameWrapper>
            {truncateName({ nameToTruncate: file.name, maxLength: 30 })}
          </FileNameWrapper>
          <RemoveFileWrapper>
            <img
              src={require(`../../../img/x-green.png`).default}
              onClick={(): void => setFile(null)}
            />
          </RemoveFileWrapper>
        </FooterAttachment>
      )}
    </div>
  );
};
