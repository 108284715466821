export function isValidEmail(email: string): boolean {
  // Regular expression to match email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if email matches regular expression
  return emailRegex.test(email);
}

export function isValidDate(dateString: string): boolean {
  /**
   * date format yyyy-mm-dd
   */
  if (dateString.length !== 10) {
    return false;
  }

  if (dateString[4] !== "-" || dateString[7] !== "-") {
    return false;
  }

  const parts = dateString.split("-");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return false;
  }

  if (
    month < 1 ||
    month > 12 ||
    day < 1 ||
    day > 31 ||
    year < 1900 ||
    year > new Date().getFullYear()
  ) {
    return false;
  }

  const dateObject = new Date(year, month - 1, day);
  if (
    dateObject.getMonth() + 1 !== month ||
    dateObject.getDate() !== day ||
    dateObject.getFullYear() !== year
  ) {
    return false;
  }

  return true;
}

export const truncateName = ({
  nameToTruncate,
  maxLength = 50,
}: {
  nameToTruncate: string;
  maxLength: number;
}): string => {
  if (nameToTruncate.length > maxLength) {
    const partLength = Math.floor((maxLength - 3) / 2);
    return (
      nameToTruncate.substr(0, partLength) +
      "..." +
      nameToTruncate.substr(nameToTruncate.length - partLength)
    );
  } else {
    return nameToTruncate;
  }
};

function toSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function deepKeysToSnakeCase(obj: any): any {
  if (obj !== Object(obj)) return obj;

  if (Array.isArray(obj)) {
    return obj.map(deepKeysToSnakeCase);
  }

  return Object.keys(obj).reduce((res: { [key: string]: any }, key: string) => {
    const snakeCaseKey = toSnakeCase(key);
    let val = obj[key];

    if (Array.isArray(val) || (val !== null && val.constructor === Object)) {
      val = deepKeysToSnakeCase(val);
    }

    res[snakeCaseKey] = val;
    return res;
  }, {});
}
