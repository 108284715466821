/* eslint-disable @typescript-eslint/no-var-requires */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BubbleIcon } from "./BubbleIcon";
import { ConversationBox } from "./ConversationBox";
import { useSelector } from "react-redux";
import { RootState } from "../../api/store";

interface WrapperProps {
  show: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  bottom: 10%;
  right: 150px;
  ${({ show }): string =>
    show
      ? `
   transform: translate(calc(100% + 15px), 40px) scale(1);
  `
      : `
   transform: translate(calc(100% + 15px), 40px) scale(0);
  `}

  transition: 0.2s linear;
  z-index: 9;
`;

export const Chat: React.FC = () => {
  const [show, setShow] = useState(false);

  const selectedUserData = useSelector(
    (state: RootState) => state.message.selectedUser
  );

  useEffect(() => {
    setShow(!!selectedUserData?.id);
  }, [selectedUserData]);

  return (
    <Wrapper show={show}>
      {show && (
        <>
          <BubbleIcon />
          <ConversationBox />
        </>
      )}
    </Wrapper>
  );
};
