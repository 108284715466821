/* eslint-disable @typescript-eslint/no-var-requires */

import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../api/store";
import { ChatIconType } from "../../api/messageSlice";

const BubbleIconWrapper = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #10decc;
  display: grid;
  place-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  &.open {
    transform: translate(calc(100% + 15px), 40px) scale(1);
    @include mq("main-table") {
      transform: translate(100%, 0) scale(1);
    }
  }
`;

const AttachmentStyledIcon = styled.img`
  height: 30px !important;
  width: 25px !important;
`;

export const BubbleIcon: React.FC = () => {
  const chatType: ChatIconType = useSelector(
    (state: RootState) => state.message.chatType
  );
  const renderIcon = (): JSX.Element => {
    if (chatType === ChatIconType.ENVELOPE) {
      return <img src={require(`../../../img/envelope-white.png`).default} />;
    } else {
      return (
        <AttachmentStyledIcon
          src={require(`../../../img/attachment.png`).default}
        />
      );
    }
  };

  return <BubbleIconWrapper>{renderIcon()}</BubbleIconWrapper>;
};
