import $ from "jquery";

export const loadExternalScript = (
  url: string,
  callback: () => void = null
): void => {
  const t = document.getElementsByTagName("script")[0];
  if (t !== undefined) {
    const e = document.createElement("script");
    e.type = "text/javascript";
    e.async = true;
    e.src = url;
    e.charset = "UTF-8";
    t.parentNode.insertBefore(e, t);
    e.onload = callback;
  }
};

function submitClosestForm(element: HTMLElement): void {
  const form = $(element).closest("form");
  setTimeout(function () {
    form.submit();
  });
}

window.addEventListener("load", () => {
  // base.html
  require("../utils/base");
  require("../utils/d.ajax");
  if (!document.body) {
    return;
  }
  const { classList } = document.body;
  // product/layout.html
  // if (document.querySelector(".product-detail")) {
  //   require("../legacy/t.product-detail");
  // }
});
