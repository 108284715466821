import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageDetailType, UserDetail } from "./action";

export enum ChatIconType {
  ENVELOPE = "envelope",
  ATTACHMENT = "attachment",
}

export type MessageState = {
  chatType: ChatIconType;
  isLoading: boolean;
  messageList: MessageDetailType[] | null;
  permissionDenied: boolean;
  selectedUser: Partial<UserDetail> | null;
};

const messageReducer = createSlice({
  name: "messageReducer",
  initialState: {
    chatType: ChatIconType.ENVELOPE,
    isLoading: false,
    messageList: null,
    permissionDenied: false,
    selectedUser: null,
  } as MessageState,
  reducers: {
    setChatType: (
      state,
      { payload: chatType }: PayloadAction<ChatIconType>
    ) => {
      state.chatType = chatType;
    },
    setMessageIsLoading: (
      state,
      { payload: isLoading }: PayloadAction<boolean>
    ) => {
      state.isLoading = isLoading;
    },
    setMessagePermissionDenied: (
      state,
      { payload: permissionDenied }: PayloadAction<boolean>
    ) => {
      state.permissionDenied = permissionDenied;
    },
    setMessageSelectedUser: (
      state,
      { payload: data }: PayloadAction<Partial<UserDetail>>
    ) => {
      state.selectedUser = data;
    },
    setMessageList: (
      state,
      { payload: data }: PayloadAction<MessageDetailType[]>
    ) => {
      state.messageList = data;
    },
  },
});

export const {
  setChatType,
  setMessageIsLoading,
  setMessageList,
  setMessagePermissionDenied,
  setMessageSelectedUser,
} = messageReducer.actions;

export default messageReducer.reducer;
