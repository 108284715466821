/* eslint-disable @typescript-eslint/no-var-requires */

import React from "react";
import { ToolbarNodeDetails, ToolbarNodeType } from "./ToolbarNode";
import { Doctor } from "./user/User";
import { useSelector } from "react-redux";
import { RootState } from "../../api/store";
import { getLoggedUser, setSelectedNodeAction } from "../../api/storeActions";

export const ToolbarBottomSection: React.FC = () => {
  const loggedUser = getLoggedUser();
  const selectedNode: ToolbarNodeType = useSelector(
    (state: RootState) => state.toolbar.selectedNode
  );

  const closeToolbar = (): void => {
    // easiest way to directly manipulate DOM element - but not best
    document.getElementById("toolbar").classList.remove("show");
  };

  const backTo = ToolbarNodeDetails[selectedNode].backTo;

  if (backTo) {
    return (
      <div className="section section-bottom row">
        <div
          className="option"
          onClick={(): void => setSelectedNodeAction(backTo)}
        >
          <img src={require("../../../img/arrow-right-white.png").default} />
          <p>back</p>
        </div>
        <div
          className="option"
          onClick={(): void => {
            setSelectedNodeAction(ToolbarNodeType.ToolbarOptionList);
            closeToolbar();
          }}
        >
          <img
            src={require("../../../img/arrow-right-white.png").default}
            className="no-margin"
          />
          <img src={require("../../../img/arrow-right-white.png").default} />
          <p>close</p>
        </div>
      </div>
    );
  }
  return (
    <div className="section section-bottom column">
      {loggedUser instanceof Doctor ? (
        <div
          className="option"
          onClick={(): string => (window.location.href = "/patient-list/")}
        >
          <img src={require("../../../img/arrow-right-white.png").default} />
          <p>go to patient list</p>
        </div>
      ) : (
        <div
          className="option"
          onClick={(): string => (window.location.href = "/forms/")}
        >
          <img src={require("../../../img/arrow-right-white.png").default} />
          <p>go to form list</p>
        </div>
      )}
      <div className="option" onClick={(): void => closeToolbar()}>
        <img src={require("../../../img/arrow-right-white.png").default} />
        <p>close</p>
      </div>
      <div
        className="option"
        onClick={(): string => (window.location.href = "/logout/")}
      >
        <img src={require("../../../img/logout.png").default} />
        <p>logout</p>
      </div>
    </div>
  );
};
