import React from "react";
import {
  ToolbarNodeDetails,
  ToolbarNodeListType,
  ToolbarTopSectionDetails,
} from "./ToolbarNode";
import { useSelector } from "react-redux";
import { RootState } from "../../api/store";
import { User } from "./user/User";

export const ToolbarTopSection: React.FC = () => {
  const selectedNode: ToolbarNodeListType = useSelector(
    (state: RootState) => state.toolbar.selectedNode
  );
  const details: ToolbarTopSectionDetails =
    ToolbarNodeDetails[selectedNode].topSection;

  // as this can be Doctor / Patient / Parent and we need only fullname
  const selectedUser = new User(
    useSelector((state: RootState) => state.toolbar.selectedUser)
  );

  const selectedParentData = useSelector(
    (state: RootState) => state.toolbar.selectedParent
  );

  const selectedParent = selectedParentData
    ? new User(selectedParentData)
    : null;

  return (
    <div className="section section-top">
      <h3>
        {details.text}
        {details.includeSelectedUserName ? selectedUser.fullName : ""}
        {details.includeSelectedParentName ? selectedParent?.fullName : ""}
      </h3>
    </div>
  );
};
