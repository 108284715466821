import React from "react";
import {
  PatientRowHeader,
  PermissionDeniedMainTableWrapper,
} from "./FormSection";
import { PermissionDenied } from "../toolbar/PermissionDenied";
import styled from "styled-components";

type Props = {
  header?: string;
};

const Wrapper = styled.div`
  border: 2px solid #ebfdfc;
`;

export const PermissionDeniedSection: React.FC<Props> = ({ header }) => (
  <Wrapper>
    {header && <PatientRowHeader>{header}</PatientRowHeader>}
    <PermissionDeniedMainTableWrapper>
      <PermissionDenied />
    </PermissionDeniedMainTableWrapper>
  </Wrapper>
);
