/* eslint-disable @typescript-eslint/no-var-requires */

import React from "react";
import styled from "styled-components";

const InfoWrapper = styled.div`
  border: 1px solid white;
  margin: 50px 50px 50px 60px;
  text-align: center;
  width: 80%;
  box-sizing: border-box;
  }
`;

const HeaderWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }
`;

const StyledImg = styled.img`
  margin: 0 !important;
  height: 50px !important;
  width: 50px !important;
`;

const StyledHeader = styled.p`
  border-bottom: 1px solid white;
  color: white;
  display: inline;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
`;

const StyledP = styled.p`
  color: white;
  width: 100%;
`;

type Props = {
  htmlContent: string;
  icon?: "warning" | "error";
  header?: string;
};

export const UserInformation: React.FC<Props> = ({
  htmlContent,
  icon = "warning",
  header,
}) => (
  <InfoWrapper>
    <HeaderWrapper>
      <StyledImg src={require(`../../../../img/${icon}.png`).default} />
      <StyledHeader>{header ? header : "Important information"}</StyledHeader>
    </HeaderWrapper>
    <StyledP dangerouslySetInnerHTML={{ __html: htmlContent }}></StyledP>
  </InfoWrapper>
);
