import { renderReduxElement } from "./renderutils";

import ReactDOM from "react-dom";

const $ = require("jquery");

// const { glue, REMOTE } = window;

const all = function () {
  $("[data-react-component]:not(.bound)")
    .each(function () {
      const element = $(this)[0];
      ReactDOM.render(renderReduxElement(element.dataset), element);
    })
    .addClass("bound");
};
window.rebindAll = all;

// scroll to top of the page
$(function () {
  setTimeout(function () {
    const scroll = $("[data-scroll]").first();
    const scrollTop = (scroll.offset() || {}).top - 80;

    $("html,body").scrollTop(scrollTop);
  });
});

// Bind everything when page loaded
$(all);
