/* eslint-disable @typescript-eslint/no-var-requires */

import React from "react";
import { Provider } from "react-redux";
import { store } from "../api/store";
import { setCredentials } from "../api/authSlice";
import { Chat } from "../components/chat/Chat";

export const getComponentByName = (name: string): React.ComponentType => {
  switch (name) {
    case "Toolbar":
      return require("../components/toolbar/Toolbar").Toolbar;
    case "PatientList":
      return require("../components/patient_list/PatientList").PatientList;
    case "Chat":
      return require("../components/chat/Chat").Chat;
    default: {
      throw new Error(`Not implemented: ${name}`);
    }
  }
};

export const renderReduxElement = (dataset: DOMStringMap): JSX.Element => {
  const Component = getComponentByName(dataset.reactComponent);
  const props = Object.assign({}, dataset);
  const { token } = props;
  if (token) {
    store.dispatch(setCredentials({ token }));
    delete props.token;
  }
  return (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );
};
