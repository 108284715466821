import React from "react";

import styled from "styled-components";

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;

export const Spinner: React.FC = () => (
  <StyledDiv>
    <div className="custom-loader2"></div>
  </StyledDiv>
);
