import {
  getFormDetails,
  MessageDetailType,
  messageList,
  UserDetail,
  userList,
  UserListType,
  userManagement,
  UserManagementAction,
} from "./action";
import { Doctor, User, UserRole } from "../components/toolbar/user/User";
import { RootState, store } from "./store";
import { setLoggedUser } from "./authSlice";
import { ToolbarNodeType } from "../components/toolbar/ToolbarNode";
import {
  setSelectedNode,
  setSelectedParent,
  setSelectedUser,
} from "./toolbarSlice";
import {
  deleteParentForceReloadMainTable,
  setFormDetails,
  setParentForceReloadMainTable,
  setPatientListMainTable,
  setPatientListToolbar,
} from "./patientSlice";
import {
  deleteDoctorForceReloadMainTable,
  setDoctorForceReloadMainTable,
  setDoctorList,
} from "./doctorSlice";
import { useSelector } from "react-redux";
import {
  ChatIconType,
  setChatType,
  setMessageIsLoading,
  setMessageList,
  setMessagePermissionDenied,
  setMessageSelectedUser,
} from "./messageSlice";
import {
  DoctorListSortType,
  PatientListSortType,
} from "../components/toolbar/user/UserListActions";
import { DropdownTreeMenuItem } from "../components/patient_list/SubMenuDropdownButton";

export const getLoggedUserAction = (force = false): void => {
  const loggedUser = store.getState().auth.loggedUser;
  const token = store.getState().auth.token;
  if (!loggedUser || force) {
    userManagement({
      request: {
        action: UserManagementAction.LOGGED_USER_DETAIL,
      },
      token,
    }).then((data: UserDetail) => store.dispatch(setLoggedUser(data)));
  }
};

export const getLoggedUser = (): Doctor | User | void => {
  const data = useSelector((state: RootState) => state.auth.loggedUser);
  if (data) {
    if (data.role == UserRole.DOCTOR) return new Doctor(data);
    else return new User(data);
  }
};

export const setSelectedNodeAction = (node: ToolbarNodeType): void => {
  //todo add option to reset selectedUser depending on selectedNode
  // const selectedUserKeepNodes = [ToolbarNodeType.ParentDelete]
  const selectedParentKeepNodes = [
    ToolbarNodeType.PatientParentEdit,
    ToolbarNodeType.ParentDelete,
  ];
  if (!selectedParentKeepNodes.includes(node)) {
    setSelectedParentAction(null);
  }
  store.dispatch(setSelectedNode(node));
};

export const setSelectedParentAction = (data: UserDetail | null): void => {
  store.dispatch(setSelectedParent(data));
};

export const setSelectedUserAction = (data: UserDetail | null): void => {
  store.dispatch(setSelectedUser(data));
};

export const updateSelectedUserAction = ({
  prevUserData,
  newUserData,
}: {
  prevUserData: UserDetail;
  newUserData: UserDetail;
}): void => {
  // update currently selected user with new data values

  const prevData = { ...prevUserData };
  const newData = { ...newUserData };
  for (const key in prevData) {
    // @ts-ignore
    if (!newData[key] || newData[key] === undefined) {
      // @ts-ignore
      newData[key] = prevData[key];
    }
  }
  setSelectedUserAction(newData);
};

export const getPatientListToolbarAction = ({
  force = false,
  pageNumber,
  pageSize,
  loadMore = false,
  reset = false,
  search = null,
  sort = null,
}: {
  force?: boolean;
  loadMore?: boolean;
  pageNumber?: number;
  pageSize?: number;
  reset?: boolean;
  search?: string;
  sort?: DoctorListSortType | PatientListSortType;
}): Promise<void> => {
  const patientList: UserListType = store.getState().patient.patientListToolbar;
  if (!patientList?.result?.length || force || loadMore) {
    const token = store.getState().auth.token;
    if (!pageNumber) {
      pageNumber = patientList?.paginator.page_number || 1;
    }
    if (!pageSize) {
      pageSize = patientList?.paginator.page_size || null;
    }
    if (loadMore) {
      pageNumber += 1;
    }
    if (reset) {
      // needed to reset state to allow PatientList / DoctorList recognize changes in UseEffect (checking if queryset id's changes)
      store.dispatch(setPatientListToolbar({ patientDataList: null }));
      pageNumber = 1;
    }
    return userList({
      role: UserRole.PATIENT,
      token,
      search,
      sort,
      pageNumber,
      pageSize,
    }).then((patientDataList) => {
      store.dispatch(setPatientListToolbar({ patientDataList, loadMore }));
    });
  }
  return Promise.resolve();
};

export const updatePatientMainTableAction = ({
  patientId,
  updateDict,
}: {
  patientId: number;
  updateDict: Partial<UserDetail>;
}): void => {
  const patientList: UserListType = store.getState().patient
    .patientListMainTable;
  if (patientList?.result?.length) {
    store.dispatch(
      setPatientListMainTable({
        ...patientList,
        result: patientList?.result.map((patient: UserDetail) => {
          if (patient.id == patientId) {
            const newPatient = { ...patient };
            for (const key in updateDict) {
              // @ts-ignore
              newPatient[key] = updateDict[key];
            }
            return newPatient;
          }
          return patient;
        }),
      })
    );
  }
};

export const getFormDetailsAction = (force = false): Promise<void> => {
  const formDetails = store.getState().patient.formDetails;
  if (!Object.keys(formDetails).length || force) {
    const token = store.getState().auth.token;
    return getFormDetails({ token }).then((data) => {
      store.dispatch(setFormDetails(data));
    });
  }
  return Promise.resolve();
};

export type PatientListFiltersType = {
  doctorFilter: { value: number; label: string } | null;
  patientFilter: DropdownTreeMenuItem | null;
  statusFilter: DropdownTreeMenuItem | null;
  dobFilter: {
    date_from: Date | null;
    date_to: Date | null;
  };
};

export const PatientListFiltersInitialState: PatientListFiltersType = {
  doctorFilter: null,
  patientFilter: null,
  statusFilter: null,
  dobFilter: { date_from: null, date_to: null },
};

export const getPatientListMainTableAction = ({
  filters = PatientListFiltersInitialState,
  force = false,
  pageNumber,
  pageSize,
  reset = false,
  search = null,
  sort = null,
}: {
  filters?: object;
  force?: boolean;
  pageNumber?: number;
  pageSize?: number;
  reset?: boolean;
  search?: string;
  sort?: DoctorListSortType | PatientListSortType;
}): Promise<void> => {
  const patientList: UserListType = store.getState().patient
    .patientListMainTable;
  if (!patientList?.result?.length || force) {
    const token = store.getState().auth.token;
    if (!pageNumber) {
      pageNumber = patientList?.paginator.page_number || 1;
    }
    if (!pageSize) {
      pageSize = patientList?.paginator.page_size || null;
    }
    if (reset) {
      // needed to reset state to allow PatientList / DoctorList recognize changes in UseEffect (checking if queryset id's changes)
      store.dispatch(setPatientListMainTable(null));
      pageNumber = 1;
    }
    return userList({
      filters,
      pageNumber,
      pageSize,
      role: UserRole.PATIENT_MAIN_LIST,
      search,
      sort,
      token,
    }).then((userList) => {
      store.dispatch(setPatientListMainTable(userList));
    });
  }
  return Promise.resolve();
};

export const refreshPatientListMainTableByUser = (
  selectedUserId: number,
  role: UserRole
): void => {
  if (role == UserRole.PARENT) {
    return;
  }

  // refresh only when updated user from toolbar exists in current main table user list (patient column)
  const mainTablePatientList: UserDetail[] = store.getState().patient
    .patientListMainTable?.result;

  const getId = (userData: UserDetail): number => {
    if (role == UserRole.PATIENT) {
      return userData.id;
    }
    if (role == UserRole.DOCTOR) {
      return userData.doctor_id;
    }
  };
  if (
    mainTablePatientList.filter((patient) => getId(patient) == selectedUserId)
      .length
  ) {
    getPatientListMainTableAction({ force: true });
  }
};

export const getDoctorListToolbarAction = ({
  force = false,
  pageNumber,
  pageSize,
  loadMore = false,
  reset = false,
  search = null,
  sort = null,
}: {
  force?: boolean;
  loadMore?: boolean;
  pageNumber?: number;
  pageSize?: number;
  reset?: boolean;
  search?: string;
  sort?: DoctorListSortType | PatientListSortType;
}): Promise<void> => {
  const doctorList = store.getState().doctor.doctorList;
  if (!doctorList?.result?.length || force || loadMore) {
    const token = store.getState().auth.token;
    if (!pageNumber) {
      pageNumber = doctorList?.paginator.page_number || 1;
    }
    if (!pageSize) {
      pageSize = doctorList?.paginator.page_size || null;
    }
    if (loadMore) {
      pageNumber += 1;
    }
    if (reset) {
      // needed to reset state to allow PatientList / DoctorList recognize changes in UseEffect (checking if queryset id's changes)
      store.dispatch(setDoctorList({ doctorDataList: null }));
      pageNumber = 1;
    }
    return userList({
      role: UserRole.DOCTOR,
      token,
      search,
      sort,
      pageNumber,
      pageSize,
    }).then((doctorDataList) => {
      store.dispatch(setDoctorList({ doctorDataList, loadMore }));
    });
  }
  return Promise.resolve();
};

export const putParentForceReloadMainTable = (parentId: number): void => {
  store.dispatch(setParentForceReloadMainTable(parentId));
};

export const removeParentForceReloadMainTable = (parentId: number): void => {
  store.dispatch(deleteParentForceReloadMainTable(parentId));
};

export const putDoctorForceReloadMainTable = (doctorId: number): void => {
  store.dispatch(setDoctorForceReloadMainTable(doctorId));
};

export const removeDoctorForceReloadMainTable = (doctorId: number): void => {
  store.dispatch(deleteDoctorForceReloadMainTable(doctorId));
};

export const getChatMessages = ({
  onlyAttachments,
  patientId,
}: {
  onlyAttachments: boolean;
  patientId: number;
}): Promise<void> => {
  const token = store.getState().auth.token;
  return messageList({ onlyAttachments, patientId, token })
    .then((messages) => {
      store.dispatch(setMessageIsLoading(false));
      setChatMessages(messages);
    })
    .catch((error) => {
      console.log(error);
      store.dispatch(setMessageIsLoading(false));
      store.dispatch(setMessagePermissionDenied(true));
    });
};

export const setPatientChat = (
  data: Partial<UserDetail> | null,
  onlyAttachments = false
): void => {
  if (!data) {
    store.dispatch(setMessageSelectedUser(null));
    setChatMessages(null);
  } else {
    store.dispatch(setMessageIsLoading(true));
    store.dispatch(setMessagePermissionDenied(false));
    store.dispatch(setMessageSelectedUser(data));
    store.dispatch(
      setChatType(
        onlyAttachments ? ChatIconType.ATTACHMENT : ChatIconType.ENVELOPE
      )
    );
    getChatMessages({ onlyAttachments, patientId: data.id });
  }
};

export const setChatMessages = (
  messageList: MessageDetailType[] | null
): void => {
  store.dispatch(setMessageList(messageList));
};
