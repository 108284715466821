/* eslint-disable @typescript-eslint/no-empty-function */

import React from "react";
import styled from "styled-components";
import { PaginatorType } from "../../api/action";
import ReactPaginate from "react-paginate";
import { Spinner } from "../Spinner";
import CustomDropdown from "../Dropdown";

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
  width: 100%;

  div {
    transform: scale(0.6);
  }
`;

const StyledPaginate = styled(ReactPaginate)`
  display: flex;
  justify-content: center;

  .page {
    border: 1px solid white;
    border-left: unset;
    background-color: #84b1b5;
    cursor: pointer;

    &:nth-child(1) {
      border-left: 1px solid white;
    }

    a {
      display: block;
      padding: 5px 10px;
      color: white;
      font-weight: 400;
      font-size: 12px;
      text-transform: uppercase;
    }

    &.active {
      background-color: rgb(0, 225, 206);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  p {
    font-size: 12px;
  }
`;

type OptionType = { value: number; label: string };

type Props = {
  onChangePageSize: (selectedOption: OptionType) => void;
  onChangePageNumber: (selectedItem: { selected: number }) => void;
  pageSize: OptionType;
  pageSizeChange: boolean;
  paginator: PaginatorType;
};

export const Paginator: React.FC<Props> = ({
  onChangePageNumber,
  onChangePageSize,
  pageSize,
  pageSizeChange,
  paginator,
}) => {
  const renderPaginationSection = (): JSX.Element => {
    return (
      <div>
        <StyledPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"page"}
          pageCount={paginator.total_pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={onChangePageNumber}
          forcePage={pageSizeChange ? 0 : undefined}
          containerClassName={"pagination"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
          nextClassName={"page"}
          previousClassName={"page"}
          pageClassName={"page"}
        />
        <StyledDiv>
          <p>Per page:</p>
          <CustomDropdown
            isSearchable={false}
            isMulti={false}
            placeholder="Sort by:"
            options={[
              { value: 20, label: "20" },
              { value: 50, label: "50" },
            ]}
            onChange={onChangePageSize}
            selected={pageSize}
            // @ts-ignore
            customClassName="main-table-dropdown-container"
          />
        </StyledDiv>
      </div>
    );
  };

  if (!paginator) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return Object.keys(paginator).length
    ? paginator.total_pages > 1 && renderPaginationSection()
    : null;
};
