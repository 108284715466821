/* eslint-disable @typescript-eslint/no-var-requires */

import React from "react";
import styled from "styled-components";

const AttachmentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  position: relative;
`;

const AttachmentNameWrapper = styled.div`
  flex-grow: 1;
`;

const AttachmentSection = styled.div`
  padding-right: 10px;
`;

const StyledImg = styled.img`
  cursor: pointer;
`;
const DownloadButton = styled.a`
  position: relative;
  display: block;
  background-color: white;
  height: 40px;
  flex-basis: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: 10px;
  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(1.2);
  }
`;

type Props = {
  attachment: any;
  attachmentName: string;
};

export const MessageDetailsAttachment: React.FC<Props> = ({
  attachment,
  attachmentName,
}) => (
  <AttachmentWrapper>
    <AttachmentSection>
      <StyledImg src={require(`../../../img/attachment.png`).default} />
    </AttachmentSection>
    <AttachmentNameWrapper>{attachmentName}</AttachmentNameWrapper>
    <DownloadButton href={attachment} download>
      <img src={require(`../../../img/download-blue.png`).default} />
    </DownloadButton>
  </AttachmentWrapper>
);
