/* eslint-disable @typescript-eslint/no-var-requires */

import React from "react";
import styled from "styled-components";
import { ConversationBoxBody } from "./ConversationBoxBody";
import { ConversationBoxHeader } from "./ConversationBoxHeader";
import { ConversationBoxFooter } from "./ConversationBoxFooter";
import { ChatIconType } from "../../api/messageSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../api/store";
import { User, UserStatus } from "../toolbar/user/User";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -200%;
  bottom: 150%;
  width: 440px;
  height: 500px;
  border-radius: 20px;
  z-index: 9;
  // transform: translateX(-50%) scaleY(0);
  transform: translateX(-50%) scaleY(1);
  background: #e9fbfb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: 0.15s linear;
  transform-origin: 50% -20px;
  justify-content: flex-end;

  &::before {
    height: 15px;
    width: 15px;
    position: absolute;
    bottom: -7px;
    left: 84%;
    content: "";
    background: #e9fbfb;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: translateX(-50%) rotate(-135deg);
  }

  &::after {
    height: 15px;
    width: 50px;
    position: absolute;
    content: "";
    bottom: 0;
    left: 84%;
    transform: translateX(-50%);
    background: #e9fbfb;
  }

  &.open {
    transform: translateX(-50%) scaleY(1);
  }
`;

export const ConversationBox: React.FC = () => {
  const selectedUserData = useSelector(
    (state: RootState) => state.message.selectedUser
  );
  const selectedUser = new User(selectedUserData);
  const chatType: ChatIconType = useSelector(
    (state: RootState) => state.message.chatType
  );
  const permissionDenied: boolean = useSelector(
    (state: RootState) => state.message.permissionDenied
  );

  const showFooter =
    selectedUserData &&
    selectedUser.status !== UserStatus.PatientAccountArchived &&
    chatType === ChatIconType.ENVELOPE;

  return (
    <Wrapper>
      <ConversationBoxHeader />
      <ConversationBoxBody />
      {showFooter && !permissionDenied && <ConversationBoxFooter />}
    </Wrapper>
  );
};
