/* eslint-disable @typescript-eslint/no-var-requires */

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface StyledPProps {
  color?: string;
}

const StyledP = styled.p<StyledPProps>`
  font-size: 12px;
  margin-right: 24px;
  color: ${({ color }): string => color || "#3c5153"} !important;
`;

const ResetButton = styled.img`
  margin-right: 5px !important;
  height: 10px !important;
  width: 10px !important;
  margin-left: 10px !important;
  cursor: pointer;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

interface MenuProps {
  isOpen: boolean;
}

const DropdownMenu = styled.div<MenuProps>`
  display: ${({ isOpen }): string => (isOpen ? "block" : "none")};
  position: absolute;
  top: 50px;
  left: 0;
  min-width: 250px;
  z-index: 5;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

const AddFilterButton = styled.div`
  display: flex;
  color: white;
  background-color: #84b1b4;
  padding: 0 10px 0 15px;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  height: 46px !important;
`;

const PlusImg = styled.img`
  margin-right: 5px !important;
  height: 10px !important;
  width: 10px !important;
`;

const DateItem = styled.div`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const DateItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DateReset = styled.img`
  margin-left: 10px !important;
  height: 10px !important;
  width: 10px !important;
`;

interface Props {
  selectedOption: { date_from: Date | null; date_to: Date | null };
  setSelectedOption: (value: {
    date_from: Date | null;
    date_to: Date | null;
  }) => void;
  placeholder?: string;
  label?: string;
  showReset?: boolean;
}

export const DateRangePicker: React.FC<Props> = ({
  selectedOption,
  setSelectedOption,
  placeholder,
  label,
  showReset = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent): void => {
      if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return (): void => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  const handleButtonClick = (): void => {
    setIsOpen(!isOpen);
  };

  const handleClearClick = (): void => {
    setIsOpen(false);
    setSelectedOption({ date_from: null, date_to: null });
  };

  const handleDateChange = (
    date: Date | null,
    type: "date_from" | "date_to"
  ): void => {
    setSelectedOption({ ...selectedOption, [type]: date });
  };

  let displayText = "";
  if (selectedOption.date_from) {
    displayText += `From: ${selectedOption.date_from.toLocaleDateString()} `;
  }
  if (selectedOption.date_to) {
    displayText += `To: ${selectedOption.date_to.toLocaleDateString()}`;
  }

  return (
    <DropdownContainer>
      {label ? <StyledP>{label}</StyledP> : null}
      <DropdownContainer ref={ref}>
        <AddFilterButton onClick={handleButtonClick}>
          <StyledP color={"white"}>
            {selectedOption.date_from || selectedOption.date_to ? (
              displayText
            ) : (
              <>
                <PlusImg src={require("../../../img/plus-white.png").default} />
                {placeholder}
              </>
            )}
          </StyledP>
        </AddFilterButton>
        <DropdownMenu isOpen={isOpen}>
          <DateItem>
            From date:
            <DateItemWrapper>
              <DatePicker
                selected={selectedOption.date_from}
                maxDate={selectedOption.date_to}
                onChange={(date: Date): void =>
                  handleDateChange(date, "date_from")
                }
              />
              <DateReset
                onClick={(): void => handleDateChange(null, "date_from")}
                src={require("../../../img/x-dark-green.png").default}
              />
            </DateItemWrapper>
          </DateItem>
          <DateItem>
            To date:
            <DateItemWrapper>
              <DatePicker
                selected={selectedOption.date_to}
                minDate={selectedOption.date_from}
                maxDate={new Date()}
                onChange={(date: Date): void =>
                  handleDateChange(date, "date_to")
                }
              />
              <DateReset
                onClick={(): void => handleDateChange(null, "date_to")}
                src={require("../../../img/x-dark-green.png").default}
              />
            </DateItemWrapper>
          </DateItem>
        </DropdownMenu>
        {showReset && (selectedOption.date_from || selectedOption.date_to) && (
          <ResetButton
            onClick={handleClearClick}
            src={require("../../../img/x-dark-green.png").default}
          />
        )}
      </DropdownContainer>
    </DropdownContainer>
  );
};
