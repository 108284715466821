import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserListType } from "./action";

export type DoctorState = {
  doctorList: UserListType;
  // used for main table. When you select user, parent section will emerge.
  // You click "edit in toolbar" button, so you are redirected to toolbar.
  // You edit parent there -> that action trigger this state.
  // It allows to reload parent
  doctorForceReloadMainTable: number[]; //doctorId[]
};

const doctorSlice = createSlice({
  name: "doctorSlice",
  initialState: {
    doctorList: null,
    doctorForceReloadMainTable: [],
  } as DoctorState,
  reducers: {
    setDoctorList: (
      state,
      {
        payload: { doctorDataList, loadMore = false },
      }: PayloadAction<{ doctorDataList: UserListType; loadMore?: boolean }>
    ) => {
      if (loadMore) {
        const prevResult = state.doctorList?.result || [];
        state.doctorList = {
          ...doctorDataList,
          result: [...prevResult, ...(doctorDataList?.result || [])],
        };
      } else {
        state.doctorList = doctorDataList;
      }
    },
    setDoctorForceReloadMainTable: (
      state,
      { payload: doctorId }: PayloadAction<number>
    ) => {
      state.doctorForceReloadMainTable = [
        ...state.doctorForceReloadMainTable,
        doctorId,
      ];
    },
    deleteDoctorForceReloadMainTable: (
      state,
      { payload: parentId }: PayloadAction<number>
    ) => {
      state.doctorForceReloadMainTable = state.doctorForceReloadMainTable.filter(
        (item) => item !== parentId
      );
    },
  },
});

export const {
  setDoctorList,
  setDoctorForceReloadMainTable,
  deleteDoctorForceReloadMainTable,
} = doctorSlice.actions;

export default doctorSlice.reducer;
