import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDetail } from "./action";

export type AuthState = {
  token: string;
  loggedUser: UserDetail;
};

const authSlice = createSlice({
  name: "auth",
  initialState: { token: null, loggedUser: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>
    ) => {
      state.token = token;
    },
    setLoggedUser: (
      state,
      { payload: userData }: PayloadAction<UserDetail>
    ) => {
      state.loggedUser = userData;
    },
  },
});

export const { setCredentials, setLoggedUser } = authSlice.actions;

export default authSlice.reducer;
