/* eslint-disable @typescript-eslint/no-var-requires */

import React from "react";
import styled from "styled-components";
import { setPatientChat } from "../../api/storeActions";
import { useSelector } from "react-redux";
import { RootState } from "../../api/store";
import { User } from "../toolbar/user/User";

const Header = styled.div`
  line-height: 40px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  background-color: #84b1b5;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  justify-self: flex-end;
  position: absolute;
  top: 0;
  z-index: 9;
`;

const CloseIconWrapper = styled.div`
  display: grid;
  cursor: pointer;
  place-items: center;
  position: absolute;
  top: 20px;
  right: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: none;
  z-index: 9;
`;

export const ConversationBoxHeader: React.FC = () => {
  const selectedUserData = useSelector(
    (state: RootState) => state.message.selectedUser
  );
  const selectedUser = new User(selectedUserData);

  return (
    <Header>
      {selectedUserData && selectedUser.fullName}
      <CloseIconWrapper onClick={(): void => setPatientChat(null)}>
        <img src={require(`../../../img/x-dark-green.png`).default} />
      </CloseIconWrapper>
    </Header>
  );
};
