import React from "react";
import styled from "styled-components";
import { FormList } from "./FormList";
import { PermissionDenied } from "../toolbar/PermissionDenied";
import { PatientFormDetail } from "../../api/action";
import { Patient } from "../toolbar/user/User";

const Wrapper = styled.div`
  width: 50%;
  border: 2px solid #ebfdfc;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const PermissionDeniedMainTableWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 420px;
  p {
    color: black !important;
  }
`;

export const PatientRowHeader = styled.div`
  height: 25px;
  line-height: 25px;
  width: 100%;
  color: white;
  background-color: rgb(132, 177, 181);
  text-align: center;
  font-size: 12px;
`;

type Props = {
  formList: PatientFormDetail[];
  patient: Patient;
};

export const FormSection: React.FC<Props> = ({ formList, patient }) => {
  return (
    <Wrapper>
      <PatientRowHeader>Forms</PatientRowHeader>
      {patient.isPermissionDenied ? (
        <PermissionDeniedMainTableWrapper>
          <PermissionDenied />
        </PermissionDeniedMainTableWrapper>
      ) : (
        <FormList formList={formList} patient={patient} />
      )}
    </Wrapper>
  );
};
