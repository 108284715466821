import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormDetails, UserListType } from "./action";

export type PatientState = {
  formDetails: FormDetails;
  patientListToolbar: UserListType;
  patientListMainTable: UserListType;
  // used for main table. When you select user, parent section will emerge.
  // You click "edit in toolbar" button, so you are redirected to toolbar.
  // You edit parent there -> that action trigger this state.
  // It allows to reload parent
  parentForceReloadMainTable: number[]; //parentId[]
};

const patientSlice = createSlice({
  name: "patientSlice",
  initialState: {
    formDetails: {},
    patientListToolbar: null,
    patientListMainTable: null,
    parentForceReloadMainTable: [],
  } as PatientState,
  reducers: {
    setPatientListToolbar: (
      state,
      {
        payload: { patientDataList, loadMore = false },
      }: PayloadAction<{ patientDataList: UserListType; loadMore?: boolean }>
    ) => {
      if (loadMore) {
        const prevResult = state.patientListToolbar?.result || [];
        state.patientListToolbar = {
          ...patientDataList,
          result: [...prevResult, ...(patientDataList?.result || [])],
        };
      } else {
        state.patientListToolbar = patientDataList;
      }
    },
    setPatientListMainTable: (
      state,
      { payload: patientDataList }: PayloadAction<UserListType>
    ) => {
      state.patientListMainTable = patientDataList;
    },
    setParentForceReloadMainTable: (
      state,
      { payload: parentId }: PayloadAction<number>
    ) => {
      state.parentForceReloadMainTable = [
        ...state.parentForceReloadMainTable,
        parentId,
      ];
    },
    deleteParentForceReloadMainTable: (
      state,
      { payload: parentId }: PayloadAction<number>
    ) => {
      state.parentForceReloadMainTable = state.parentForceReloadMainTable.filter(
        (item) => item !== parentId
      );
    },
    setFormDetails: (
      state,
      { payload: formDetailsData }: PayloadAction<FormDetails>
    ) => {
      state.formDetails = formDetailsData;
    },
  },
});

export const {
  deleteParentForceReloadMainTable,
  setFormDetails,
  setParentForceReloadMainTable,
  setPatientListMainTable,
  setPatientListToolbar,
} = patientSlice.actions;

export default patientSlice.reducer;
