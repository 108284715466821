import React from "react";
import styled from "styled-components";
import { MessageDetails, MessageType } from "./MessageDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../api/store";
import { MessageDetailType } from "../../api/action";
import { Doctor, UserRole } from "../toolbar/user/User";
import { getLoggedUser } from "../../api/storeActions";
import { Spinner } from "../Spinner";
import { PermissionDeniedSection } from "../patient_list/PermissionDeniedSection";

const NoMessageWrapper = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  margin-top: 5vh;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #84b1b5;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5) !important;
    border-radius: 100px;
  }
`;

export const ConversationBoxBody: React.FC = () => {
  const loggedUser = getLoggedUser();
  const isLoading: boolean = useSelector(
    (state: RootState) => state.message.isLoading
  );
  const permissionDenied: boolean = useSelector(
    (state: RootState) => state.message.permissionDenied
  );
  const messageList: MessageDetailType[] = useSelector(
    (state: RootState) => state.message.messageList
  );
  const getMessageType = (fromUser: UserRole): MessageType => {
    if (loggedUser instanceof Doctor) {
      return fromUser == UserRole.DOCTOR
        ? MessageType.SENDER
        : MessageType.RECIPIENT;
    } else {
      return fromUser == UserRole.DOCTOR
        ? MessageType.RECIPIENT
        : MessageType.SENDER;
    }
  };

  const renderMessageList = (): JSX.Element => {
    if (isLoading) {
      return (
        <NoMessageWrapper>
          <Spinner />
        </NoMessageWrapper>
      );
    }
    if (permissionDenied) {
      return (
        <NoMessageWrapper>
          <PermissionDeniedSection />
        </NoMessageWrapper>
      );
    }
    return (
      <>
        {messageList?.length ? (
          messageList.map((message) => (
            <MessageDetails
              key={message.id}
              message={message}
              messageType={getMessageType(message.from_user)}
            />
          ))
        ) : (
          <NoMessageWrapper>There is no message</NoMessageWrapper>
        )}
      </>
    );
  };

  return <Body>{renderMessageList()}</Body>;
};
