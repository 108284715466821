/* eslint-disable @typescript-eslint/no-var-requires */

import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
  margin: 30px 0;
`;

const SearchIconWrapper = styled.div`
  height: 100%;
  background-color: #00e1ce;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0 20px 20px 0;
  padding: 12px 20px;
  cursor: pointer;

  img {
    max-height: 23px;
  }
`;

const StyledInput = styled.input`
  color: #84b1b5;
  font-size: 14px;
  padding: 13px;
  border-radius: 20px 0 0 20px !important;
  width: 250px;
  max-width: 320px;
`;

type Props = {
  searchBy: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const SearchInput: React.FC<Props> = ({ searchBy, onChange }) => {
  return (
    <StyledDiv>
      <StyledInput
        type="text"
        placeholder="Search patient..."
        onChange={onChange}
        value={searchBy}
      />
      <SearchIconWrapper>
        <img src={require("../../../img/search-icon.png").default} />
      </SearchIconWrapper>
    </StyledDiv>
  );
};
